import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  InputGroup,
  Input,
  Button,
  Label,
  FormGroup,
  CustomInput,
} from "reactstrap";

// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

// import Redux Actions
import { searchNavigator, clearSearch } from "../../actions/analyses";

// Import components
import SearchBar from "./components/searchBar";

// Import Constants
import { NavigatorReportQuestions } from "../../lib/constants";

// Import Icons
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Form from "reactstrap/lib/Form";

class NewAnalysis extends React.Component {
  constructor() {
    super();

    this.state = {
      existingAnalyses: [],
      navigator: {},
      q: "",
      company: {},
      internationalisation: {},
      lead2scale: {},
      step: 2,
      companyForm: {
        companyUrl: { data: "", error: "", valid: false },
        competitor1: { data: "", error: "", valid: false },
        competitor2: { data: "", error: "", valid: false },
        competitor3: { data: "", error: "", valid: false },
        competitor4: { data: "", error: "", valid: false },
        competitor5: { data: "", error: "", valid: false },
      },
      internationalForm: {
        local: 100,
        international: 0,
      },
    };
  }

  componentDidMount() {
    // console.log("new analysis mount");
  }
  componentDidUpdate(prevProps, prevState) {
    // if (prevState.step !== this.state.step) {
    //   console.log(this.state);
    // }
  }
  changeInternational = (e) => {
    // console.log(e.target.value);
    if (e.target.id === "local") {
      this.setState({
        internationalForm: {
          local: Number(e.target.value),
          international: 100 - e.target.value,
        },
      });
    } else {
      this.setState({
        internationalForm: {
          local: 100 - e.target.value,
          international: Number(e.target.value),
        },
      });
    }
  };
  saveInternational = () => {
    this.setState({
      step: 4,
      internationalisation: {
        local: this.state.internationalForm.local,
        international: this.state.internationalForm.international,
      },
    });
  };

  saveCompany = (e) => {
    let urls = this.state.companyForm;
    let arr = [false, false, false, false, false];
    Object.keys(urls).map((c, i) => {
      let data = urls[c].data;
      if (urls[c].data.length > 0 || this.isValidUrl(urls[c].data)) {
        this.setState((prevState) => ({
          ...prevState,
          companyForm: {
            ...prevState.companyForm,
            [c]: {
              data: data,
              error: "false",
              valid: true,
            },
          },
        }));

        arr[i] = true;
      } else {
        this.setState((prevState) => ({
          ...prevState,
          companyForm: {
            ...prevState.companyForm,
            [c]: {
              data: data,
              error: "This is not a valid website url",
              valid: false,
            },
          },
        }));
      }
    });
    console.log(arr);
    if (arr.includes(false)) {
      console.log("company data not ready yet");
    } else {
      console.log("company data ready");
      this.setState({
        step: 3,
        company: this.state.companyForm,
      });
    }
  };

  isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  };

  handleFormFields = (e, companyForm) => {
    console.log(e.target.id, e.target.value);
    let target = (companyForm[e.target.id].data = e.target.value);

    this.setState({
      ...companyForm,
      [target]: e.target.value,
    });
  };

  searchAnalyses = (e) => {
    let query = e.target.value;
    this.setState({
      q: query,
    });

    this.props.searchNavigator(
      query,
      this.props.cookies,
      this.props.navigator.companies
    );
  };

  getExistingAnalysis = async (company) => {
    let res = await this.props.firebase.getExistingAnalyses(company);

    this.setState({
      existingAnalyses: res,
    });
  };

  getNavigatorData = (company) => {
    console.log(company);
    this.getExistingAnalysis(company.company);
    let navigatorData = {
      id: company.id,
      company: company.company,
      completionDate: company.completionDate,
      createdAt: company.createdAt,
      contact: {
        firstName: company.firstName,
        lastName: company.lastName,
        role: company.role,
      },
    };
    let surveys = company.surveys.filter((a) => a.completionDate !== null);

    surveys.map((a) => {
      let reportAnswers = a.answers.filter((b) =>
        NavigatorReportQuestions.includes(b.questionCode)
      );

      Object.assign(navigatorData, { survey: reportAnswers });
    });

    this.setState({
      navigator: navigatorData,
      q: "",
      step: 2,
    });
    this.props.clearSearch();
  };

  submitAnalysis = async () => {
    const {
      navigator,
      company,
      lead2scale,
      companyForm,
      internationalForm,
      existingAnalyses,
    } = this.state;

    const data = {
      navigator: navigator,
      company: companyForm,
      internationalisation: internationalForm,
      createAt: new Date(moment().format()),
      updatedAt: new Date(moment().format()),
      archived: false,
      ready: false,
      learning: { done: false, data: {} },
      delighted: { done: false, data: {} },
      impact: { done: false, data: {} },
    };
    await this.props.firebase
      .saveAnalyses(data, existingAnalyses)
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  componentWillUnmount() {
    console.log("new analysis unmount");
    this.props.clearSearch();
  }

  render() {
    const {
      navigator,
      company,
      internationalisation,
      lead2scale,
      q,
      step,
      companyForm,
      internationalForm,
    } = this.state;

    return (
      <div className="new-analysis">
        <div className="row">
          <div className="container">
            <div className="col col-12">
              <h1>Create an Analysis</h1>
              {/* <div className="search-bar">
                <h2>Search a ScaleUpNavigator company</h2>

                <SearchBar
                  search={this.searchAnalyses}
                  suggestions={this.props.analyses.search}
                  getNavigatorData={this.getNavigatorData}
                  query={q}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="col col-12">
              <div className="checklist">
                <ul>
                  <li>
                    {_.isEmpty(navigator) ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    ) : (
                        <span className="icon">
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                      )}
                    Navigator details
                  </li>
                  <li>
                    {_.isEmpty(company) ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    ) : (
                        <span className="icon">
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                      )}
                    Company & competitor websites
                  </li>
                  <li>
                    {_.isEmpty(internationalisation) ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    ) : (
                        <span className="icon">
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                      )}
                    Internationalisation details
                  </li>
                  <li>
                    {_.isEmpty(lead2scale) ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    ) : (
                        <span className="icon">
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                      )}
                    Lead2scale DataFile
                  </li>
                </ul>
              </div>
              {step === 1 ? (
                <>
                  {this.props.navigator.companies.length > 0 ? (
                    <>
                      <div className="search-bar">
                        <h2>Search a ScaleUpNavigator company</h2>

                        <SearchBar
                          search={this.searchAnalyses}
                          suggestions={this.props.analyses.searchNav}
                          getNavigatorData={this.getNavigatorData}
                          query={q}
                        />
                      </div>
                    </>
                  ) : (
                      <div>loading</div>
                    )}
                </>
              ) : null}

              {step === 2 ? (
                <>
                  <h2>Fill in your company details</h2>
                  <div className="form">
                    <div className="input-block">
                      <InputGroup>
                        <Input
                          onChange={(e) =>
                            this.handleFormFields(e, companyForm)
                          }
                          value={companyForm.companyUrl.data}
                          id="companyUrl"
                          name="companyUrl"
                          placeholder="Company website url"
                          type="url"
                        />
                        {this.state.companyForm["companyUrl"].valid ? (
                          <span className="input-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        ) : null}
                      </InputGroup>
                      {this.state.companyForm["companyUrl"].error.length > 0 &&
                        !this.state.companyForm["companyUrl"].valid ? (
                          <div className="error">
                            {this.state.companyForm["companyUrl"].error}
                          </div>
                        ) : null}
                    </div>
                    <div className="input-block">
                      <InputGroup>
                        <Input
                          onChange={(e) =>
                            this.handleFormFields(e, companyForm)
                          }
                          value={companyForm.competitor1.data}
                          id="competitor1"
                          name="competitor1"
                          placeholder="Competitor website 1"
                          type="url"
                        />
                        {this.state.companyForm["competitor1"].valid ? (
                          <span className="input-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        ) : null}
                      </InputGroup>
                      {this.state.companyForm["competitor1"].error.length > 0 &&
                        !this.state.companyForm["competitor1"].valid ? (
                          <div className="error">
                            {this.state.companyForm["competitor1"].error}
                          </div>
                        ) : null}
                    </div>
                    <div className="input-block">
                      <InputGroup>
                        <Input
                          onChange={(e) =>
                            this.handleFormFields(e, companyForm)
                          }
                          value={companyForm.competitor2.data}
                          id="competitor2"
                          name="competitor2"
                          placeholder="Competitor website 2"
                          type="url"
                        />
                        {this.state.companyForm["competitor2"].valid ? (
                          <span className="input-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        ) : null}
                      </InputGroup>
                      {this.state.companyForm["competitor2"].error.length > 0 &&
                        !this.state.companyForm["competitor2"].valid ? (
                          <div className="error">
                            {this.state.companyForm["competitor2"].error}
                          </div>
                        ) : null}
                    </div>
                    <div className="input-block">
                      <InputGroup>
                        <Input
                          onChange={(e) =>
                            this.handleFormFields(e, companyForm)
                          }
                          value={companyForm.competitor3.data}
                          id="competitor3"
                          name="competitor3"
                          placeholder="Competitor website 3"
                          type="url"
                        />
                        {this.state.companyForm["competitor3"].valid ? (
                          <span className="input-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        ) : null}
                      </InputGroup>
                      {this.state.companyForm["competitor3"].error.length > 0 &&
                        !this.state.companyForm["competitor3"].valid ? (
                          <div className="error">
                            {this.state.companyForm["competitor3"].error}
                          </div>
                        ) : null}
                    </div>
                    <div className="input-block">
                      <InputGroup>
                        <Input
                          onChange={(e) =>
                            this.handleFormFields(e, companyForm)
                          }
                          value={companyForm.competitor4.data}
                          id="competitor4"
                          name="competitor4"
                          placeholder="Competitor website 4"
                          type="url"
                        />
                        {this.state.companyForm["competitor4"].valid ? (
                          <span className="input-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        ) : null}
                      </InputGroup>
                      {this.state.companyForm["competitor4"].error.length > 0 &&
                        !this.state.companyForm["competitor4"].valid ? (
                          <div className="error">
                            {this.state.companyForm["competitor4"].error}
                          </div>
                        ) : null}
                    </div>
                    <div className="input-block">
                      <InputGroup>
                        <Input
                          onChange={(e) =>
                            this.handleFormFields(e, companyForm)
                          }
                          value={companyForm.competitor5.data}
                          id="competitor5"
                          name="competitor5"
                          placeholder="Competitor website 5"
                          type="url"
                        />
                        {this.state.companyForm["competitor5"].valid ? (
                          <span className="input-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                        ) : null}
                      </InputGroup>
                      {this.state.companyForm["competitor5"].error.length > 0 &&
                        !this.state.companyForm["competitor5"].valid ? (
                          <div className="error">
                            {this.state.companyForm["competitor5"].error}
                          </div>
                        ) : null}
                    </div>

                    <Button
                      onClick={() => this.setState({ step: step - 1 })}
                      color="secondary"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => this.saveCompany()}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : null}

              {step === 3 ? (
                <>
                  <h2>Fill in your website visits</h2>
                  <div className="form">
                    <div className="input-block">
                      <FormGroup>
                        <Label for="exampleRange">
                          Local traffic {internationalForm.local}%
                        </Label>

                        <Input
                          className="slider-input"
                          onChange={(e) => this.changeInternational(e)}
                          type="range"
                          name="local"
                          id="local"
                          min="0"
                          max="100"
                          value={internationalForm.local}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleRange">
                          International traffic{" "}
                          {internationalForm.international}%
                        </Label>
                        <Input
                          className="slider-input"
                          onChange={(e) => this.changeInternational(e)}
                          type="range"
                          name="international"
                          id="international"
                          min="0"
                          max="100"
                          value={internationalForm.international}
                        />
                      </FormGroup>
                    </div>

                    <Button
                      onClick={() => this.setState({ step: step - 1 })}
                      color="secondary"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => this.saveInternational()}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : null}

              {step === 4 ? (
                <>
                  <h2>Upload the lead2scale template</h2>
                  <div className="form">
                    <div className="input-block">
                      <FormGroup>
                        <CustomInput
                          type="file"
                          id="lead2scale"
                          name="lead2scale"
                          label="Choose file..."
                        />
                      </FormGroup>
                    </div>

                    <Button
                      onClick={() => this.setState({ step: step - 1 })}
                      color="secondary"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => this.submitAnalysis()}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div className="row results">
          <div className="container">
            {!_.isEmpty(navigator) ? (
              <div className="col col-4">
                <div className="widget create-widget navigator">
                  <div className="widget-title">
                    {navigator.company}
                    <span className="text-link">View details</span>
                  </div>
                </div>
              </div>
            ) : null} 
          </div>
        </div>*/}
      </div>
    );
  }
}

const mapStateToProps = ({ analyses, loading, navigator }) => {
  return {
    analyses,
    loading,
    navigator,
  };
};

export default connect(mapStateToProps, {
  searchNavigator,
  clearSearch,
})(withFirebase(withRouter(withCookies(NewAnalysis))));
