import React, { Fragment } from "react";

import { Input, Label, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { questionCats } from "../../../lib/constants";

class MtForm extends React.Component {
  render() {
    const {
      question,
      addQuestion,
      handleQuestions,
      handleAnswers,
      handleSwitch,
      handleGroup,
    } = this.props;

    return (
      <div className="col col-12">
        <div className="form-field">
          <h2 className="input-title">Management Team Questions</h2>
          <span
            className={`input-submit ${
              question.options.length === 0 ? "disabled" : ""
            }`}
            onClick={() => addQuestion(true)}
          >
            Add question
          </span>

          <div className="input-block">
            <div className="row">
              <div className="col col-5">
                <Label>Question</Label>

                <Input
                  onChange={(e) => handleQuestions(e, true)}
                  value={question.question}
                  id="question"
                  name="question"
                  placeholder="Example: Our company consists of..."
                  type="text"
                />
              </div>
              <div className="col col-5">
                <Label>Description </Label>
                <Input
                  onChange={(e) => handleQuestions(e, true)}
                  value={question.description}
                  id="description"
                  name="description"
                  placeholder="Example: (0 - strongly disagree; 1 - disagree; 2 - slightly disagree; 3 - I don't know; 4 slightly agree; 5 - agree; 6 - strongly agree.)"
                  type="text"
                />
              </div>
              <div className="col col-2">
                <Label>Question Type</Label>
                <Input
                  type="select"
                  name="type"
                  id="type"
                  value={question.type}
                  onChange={(e) => handleQuestions(e, true)}
                >
                  <option disabled value="null">
                    Choose a Type
                  </option>
                  <option value="rating">Rating</option>
                </Input>
              </div>

              {question.type === "rating" ? (
                <>
                  <div className="col col-12">
                    <div className="row sub">
                      <div className="col col-5">
                        <Label>Text Left</Label>
                        <Input
                          onChange={(e) => handleQuestions(e, true)}
                          value={question.left}
                          id="left"
                          name="left"
                          placeholder="Example: people who work towards reaching consensus"
                          type="text"
                        />
                      </div>
                      <div className="col col-4">
                        <Label>Text Right</Label>
                        <Input
                          onChange={(e) => handleQuestions(e, true)}
                          value={question.right}
                          id="right"
                          name="right"
                          placeholder="Example: Our company consists of..."
                          type="text"
                        />
                      </div>
                      <div className="col col-2 actions">
                        <div
                          className="icon add"
                          onClick={() => handleAnswers(true)}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </div>
                      </div>
                      <div className="col col-3">
                        <Label>Calculation group</Label>
                        <Input
                          type="select"
                          name="group"
                          id="group"
                          value={question.group}
                          onChange={(e) => handleGroup(e)}
                        >
                          <option disabled value="null">
                            Choose a Group
                          </option>
                          {questionCats.mt.map((g, index) => {
                            return (
                              <Fragment key={index}>
                                <option value={g.slug}>{g.title}</option>
                              </Fragment>
                            );
                          })}
                        </Input>
                      </div>
                      <div className="col col-1">
                        <FormGroup className="form-checkbox">
                          <Label check>
                            <strong>Invert score</strong>
                          </Label>
                          <div className="input-wrapper">
                            <Input
                              type="checkbox"
                              name="invert"
                              id="invert"
                              onChange={(e) => handleSwitch(e)}
                              checked={question.invert ? true : false}
                            />
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  {question.options.length > 0 ? (
                    <div className="col col-12">
                      <div className="preview">
                        {question.options.map((q, i) => {
                          return (
                            <div key={i} className="type">
                              Text left: {q.left} Text right: {q.right}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MtForm;
