export const baseUrl = process.env.REACT_APP_BASE_URL;
export const auth0BaseUrl = process.env.REACT_APP_AUTH0_BASE_URL;
export const auth0LoginPayload = {
  realm: process.env.REACT_APP_AUTH0_REALM,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  scope: "openid profile email",
  grant_type: "http://auth0.com/oauth/grant-type/password-realm",
};
export const auth0Realm = process.env.REACT_APP_AUTH0_REALM;
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const auth0Scope = "openid profile email";
export const auth0GrantType =
  "http://auth0.com/oauth/grant-type/password-realm";
export const auth0Connection = "Username-Password-Authentication";

export const localStorageLoginResponse = "currentUserLoginResponse";
export const localStorageSignupResponse = "currentUserSignupResponse";
export const localStorageCookiePreference = "localStorageCookiePreference";

export const listFields = {
  analyses: ["name"],
  navigator: ["company"],
};

export const NavigatorReportQuestions = ["SM2", "CV2", "EF10", "EF5", "GG5"];

export const questionCats = {
  main: [
    {
      id: 1,
      title: "Business Beat",
      slug: "beat",
      sub: [
        { title: "Performance Orientation", id: 1, slug: "perfomance" },
        { title: "Focus and targets", id: 2, slug: "focus" },
        { title: "Clear structure and processes", id: 3, slug: "structure" },
      ],
    },
    {
      id: 2,
      title: "Learning Orientation",
      slug: "learning",
      sub: [
        { title: "Learning velocity", id: 1, slug: "velocity" },
        { title: "Culture of experimentation", id: 2, slug: "culture" },
        { title: "External orientation", id: 3, slug: "orientation" },
      ],
    },
    {
      title: "Talent Orientation",
      slug: "talent",
      id: 3,
      sub: [
        { title: "Cultural preference", id: 1, slug: "cultural" },
        { title: "Diversity focus", id: 2, slug: "diversity" },
        { title: "Talent focus", id: 3, slug: "talent" },
      ],
    },
  ],
  mt: [
    {
      id: 4,
      title: "Directing",
      slug: "directing",
    },
    {
      id: 5,
      title: "Conducting",
      slug: "conducting",
    },
    {
      id: 6,
      title: "Exploring",
      slug: "exploring",
    },
    {
      id: 7,
      title: "Architecting",
      slug: "architecting",
    },
  ],
};
