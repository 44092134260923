import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
// import Redux Actions
import { searchNavigator, clearSearch } from "../../actions/analyses";

import { FormGroup, Input } from "reactstrap";
import "../../scss/dashboard.scss";

import "../css/normalize.css";
import "../css/menu_cornermorph.css";

class FormEdit extends React.Component {
  _initial_state = {
    surveys: [],
    menu: false,
  };

  constructor(props) {
    super(props);

    this.state = this._initial_state;
  }

  componentDidMount() {}

  render() {
    return (
      <div className={`forms`}>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <h1>Edit Survey</h1>
            </div>
          </div>
        </div>
        <div className="container surveys">
          <div className="row">
            <div className="col col-12">
              <h2>Fill in the company MT members</h2>
              <div className="form employees">
                <div className="input-block">
                  <FormGroup>
                    <Input
                      //   onChange={(e) => this.handleMT(e)}
                      //   value={companyForm.name}
                      id="name"
                      name="name"
                      placeholder="MT member Name"
                      type="text"
                    />

                    <Input
                      type="select"
                      name="role"
                      id="role"
                      defaultValue={"null"}
                      //   onChange={(e) => this.handleDropDown(e)}
                    >
                      <option disabled value="null">
                        Select MT member Role
                      </option>
                      <option value="CEO">CEO</option>
                      <option value="CFO">CFO</option>
                      <option value="COO">COO</option>
                      <option value="CTO">CTO</option>
                      <option value="COO">CMO</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyses, loading, navigator }) => {
  return {
    analyses,
    loading,
    navigator,
  };
};

export default connect(mapStateToProps, {
  searchNavigator,
  clearSearch,
})(withFirebase(withRouter(withCookies(FormEdit))));
