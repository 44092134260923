import { LOADING,SHOW_NOTICE,HIDE_NOTICE } from "../actions/types";

const initialState = {
  auth: false,
  analyses: false,
  single: false,
  search: false,
  surveys: false,
  notice:{
    show: false,
    message: ""
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return { ...state, [payload.type]: payload.payload };
    case SHOW_NOTICE:
      console.log(payload, "payload")
      console.log(type, "type")
      return { ...state, notice: {show:true, message:payload} };

      case HIDE_NOTICE:
      return { ...state, notice: {show:false, message:""} };
    default:
      return state;
  }
};
