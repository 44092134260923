import { LOADING, SHOW_NOTICE,HIDE_NOTICE } from "./types";

export const loadingToggle = (type, payload) => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: { type, payload },
  });
};

export const showNotice = (payload) => (dispatch) => {
  dispatch({
    type: SHOW_NOTICE,
    payload:payload ,
  });

  setTimeout(() => { 
    dispatch({
      type: HIDE_NOTICE,
    });
   }, 3000);
};


