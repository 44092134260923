import { GET_SURVEYS } from "./types";
import { loadingToggle } from "./loading";

export const getSurveys = (payload) => (dispatch) => {
  dispatch({
    type: GET_SURVEYS,
    payload: payload,
  });
  dispatch(loadingToggle("surveys", false));
};
