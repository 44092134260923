import React from "react";
// Import libraries
import _, { sum } from "lodash";
import moment from "moment";

// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import Draggable from "react-draggable";

class View extends React.Component {
  constructor() {
    super();
    this.state = {
      surveys: [],
      company: "",
      scores: {},
      dragging:false
    };
  }
  componentDidMount = async () => {
    this.getSurveys();
  };
  getSurveys = async () => {
    let array = this.props.match.params.id.split("&");

    let surveys = await this.props.firebase.getSurveyFromArray(array);

    let companyName = surveys[0].companyName;

    this.setState({
      company: companyName.charAt(0).toUpperCase() + companyName.slice(1),
      surveys: surveys,
    });
    this.getData(surveys);
  };
  getData = (data) => {
    let scores = {
      company: {
        beat: {
          perfomance: { average: null, scores: [] },
          focus: { average: null, scores: [] },
          structure: { average: null, scores: [] },
        },
        learning: {
          velocity: { average: null, scores: [] },
          culture: { average: null, scores: [] },
          orientation: { average: null, scores: [] },
        },
        talent: {
          cultural: { average: null, scores: [] },
          diversity: { average: null, scores: [] },
          talent: { average: null, scores: [] },
        },
      },
      mt: {},
    };
    data.map((survey) => {
      let mtQuestions = survey.survey.mt;
      mtQuestions.map((q, i) => {
        scores.mt[q.name] = {
          name: q.name,
          scores: {
            architecting: { average: null, scores: [] },
            conducting: { average: null, scores: [] },
            directing: { average: null, scores: [] },
            exploring: { average: null, scores: [] },
          },
          coordinates: [0, 0],
        };
      });
    });
    data.map((survey) => {
      let companyQuestions = survey.survey.questions;
      let mtQuestions = survey.survey.mt;
      let scoresCompany = [];
      let scoresMt = {};

      companyQuestions.map((q) => {
        q.options.map((o, i) => {
          let sub = o.group.selected.slug;
          let slug = o.group.group.slug;

          // // Test NaN
          // let rand = Math.floor(Math.random() * 11);
          // if (i === rand) o.answer = NaN;

          if (!isNaN(o.answer))
            scores.company[slug][sub].scores.push(Number(o.answer));

          scores.company[slug][sub].average = this.calculate(
            scores.company[slug][sub].scores
          );
          // scores.company[slug];
          // scoresCompany.push(Number(o.answer));
        });
      });

      mtQuestions.map((q) => {
        if (!q.skip) {
          q.questions.map((a, i) => {
            a.options.map((o, i) => {
              // console.log(o.answer, "old");
              let answer = Number(o.answer);
              if (o.invert) {
                // console.log(answer, "score");
                answer = this.invert(Number(o.answer));
                // console.log(answer, "inverted");
              }

              if (o.group) {
                let number = o.group.selected.slug;

                // // // Test NaN
                // let rand = Math.floor(Math.random() * 11);
                // if (i === rand) answer = NaN;

                if (!isNaN(answer))
                  scores.mt[q.name].scores[number].scores.push(answer);

                scores.mt[q.name].scores[number].average = this.calculate(
                  scores.mt[q.name].scores[number].scores
                );
              }
            });
          });
        }
      });
    });

    const lowLimit = 2;
    const highLimit = 6;

    Object.keys(scores.mt).map((s, i) => {
      // console.log(s);
      let score = scores.mt[s].scores;

      let arch =
        (score.architecting.average - lowLimit) / (highLimit - lowLimit);

      let dire = (score.directing.average - lowLimit) / (highLimit - lowLimit);

      let expl = (score.exploring.average - lowLimit) / (highLimit - lowLimit);

      let cond = (score.conducting.average - lowLimit) / (highLimit - lowLimit);

      // console.log(score.architecting.average, "architecting");
      // console.log(score.directing.average, "directing");
      // console.log(score.exploring.average, "exploring");
      // console.log(score.conducting.average, "conducting");

      let hor = ((arch + cond) / sum([expl, arch, dire, cond])) * 100;
      let vert = ((cond + dire) / sum([expl, arch, dire, cond])) * 100;
      // console.log(hor);
      // hor = (hor / 120) * 100;
      scores.mt[s].coordinates = [
        Number(hor.toFixed(2)),
        Number(vert.toFixed(2)),
      ];
      // console.log(scores.mt[s].coordinates);
    });
    this.setState({ scores });
  };

  calculate = (scores) => {
    var total = 0;
    for (var i = 0; i < scores.length; i++) {
      total += scores[i];
    }
    var avg = total / scores.length;
    return Number(avg.toFixed(1));
  };

  invert = (a) => {
    if (a === 1) {
      return 6;
    }

    if (a === 2) {
      return 5;
    }

    if (a === 3) {
      return 4;
    }

    if (a === 4) {
      return 3;
    }

    if (a === 5) {
      return 2;
    }
    if (a === 6) {
      return 1;
    }
  };
  handleDrag = (i) => {
    console.log(`dragging ${i}`)
    this.setState({dragging:i})
  }
  render() {
    const { company, scores,dragging } = this.state;

    return (
      <div className={`single`}>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div className="content-block">
                <h2>Company: {company}</h2>
                {!_.isEmpty(scores) ? (
                  <>
                    <div className="company-details row">
                      {Object.keys(scores.company).map((c, i) => {
                        return (
                          <div className="category col-4" key={i}>
                            <h4>{c}</h4>
                            <div className="scores-container-horizontal">
                              {Object.keys(scores.company[c]).map(
                                (a, index) => {
                                  return (
                                    <div key={index} className="item">
                                      <span className="average">
                                        <b>{a}:</b>{" "}
                                        {scores.company[c][a].average}
                                      </span>
                                      <span className="scores">
                                        {scores.company[c][a].scores.map(
                                          (s) => {
                                            return s + " ";
                                          }
                                        )}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="company-details row">
                      {Object.keys(scores.mt).map((c, i) => {
                        let mt = scores.mt[c];

                        return (
                          <div className="category col-3" key={i}>
                            <h4>{mt.name}</h4>
                            <div className="scores-container-horizontal">
                              {Object.keys(mt.scores).map((a, index) => {
                                let scores = mt.scores[a].scores;
                                let average = mt.scores[a].average;
                                return (
                                  <div key={index} className="item">
                                    <span className="average">
                                      <b>{a}:</b> {average}
                                    </span>
                                    <span className="scores">
                                      {scores.map((s) => s + " ")}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="matrix-image">
                      {Object.keys(scores.mt).map((c, i) => {
                        let hor = scores.mt[c].coordinates[0];
                        let ver = scores.mt[c].coordinates[1];

                        const options = {
                          defaultClassNameDragging: "dragging",
                        };
                        return (
                          <div key={i}>
                            {/* <Draggable options={options}> */}
                              <span
                                key={i}
                                className={`position ${i===dragging ? "active-drag":""}`}
                                style={{ left: hor + "%", bottom: ver + "%" }}
                              ></span>
                            {/* </Draggable> */}
                            <Draggable onStart={() => this.handleDrag(i)}  onStop={() => this.handleDrag(false)}>
                              <span
                                style={{
                                  left: hor + 5 + "%",
                                  bottom: ver + 8 + "%",
                                }}
                                className="name"
                              >
                                {c}
                              </span>
                            </Draggable>
                          </div>
                        );
                      })}

                      <span className="architecting">
                        <span className="explainer right">
                          <h3>Architecting</h3>
                          <p>
                            Building smart and scalable systems and processes.
                          </p>
                        </span>
                      </span>
                      <span className="conducting">
                        <span className="explainer right">
                          <h3>Conducting</h3>
                          <p>
                            Talent hiring, team building, culture development
                            and skill building.
                          </p>
                        </span>
                      </span>
                      <span className="directing">
                        <span className="explainer left">
                          <h3>Directing</h3>
                          <p>
                            Visioning, direction setting, thoughtful risk-taking
                            and managing tight execution.
                          </p>
                        </span>
                      </span>
                      <span className="exploring">
                        <span className="explainer left">
                          <h3>Exploring</h3>
                          <p>
                            Discovering new opportunities and creating the
                            appetite for new offerings.
                          </p>
                        </span>
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyses, loading, navigator, surveys }) => {
  return {
    analyses,
    loading,
    navigator,
    surveys,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withFirebase(withRouter(withCookies(View))));
