import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import config from "./config";
import { v4 as uuidv4 } from "uuid";
import api from "../apis/backend";
import _ from "lodash";
import moment from "moment";

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.rtdb = app.database();
    this.fieldValue = app.firestore.FieldValue;
    // this.analytics = app.analytics();
  }

  // doCreateUserWithEmailAndPassword = (email, password) =>
  //   this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password, props) => {
    this.auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        props.loginAction(res.user);
        localStorage.setItem("user", JSON.stringify(res.user));
      })
      .catch((err) => {
        if (err && err.message) {
          // console.log(err.message);
          props.loginError({ error: err.message, code: err.code });
        }
      });
  };

  doSignOut = () => {
    this.auth.signOut();
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  singleAnalysis = (uid) => {
    // console.log(uid);
    return this.db.collection("analyses/").doc(uid);
  };

  getSurveyTemplate = async (type, version, mtmembers) => {
    // console.log(type, version, mtmembers);
    let mt = mtmembers;
    let res = await this.db
      .collection("surveys")
      .doc(type)

      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          let snap = snapshot.data();
          let survey = snap[version];
          let questions = [];
          let mtQuestions = [];
          Object.keys(snap[version]).map((k) => {
            if (k !== "mt") questions.push(survey[k]);
            if (k === "mt")
              Object.keys(survey[k]).map((s) => {
                mtQuestions.push(survey[k][s]);
              });
          });

          mtmembers.map((m) => {
            m.questions = mtQuestions;
            return m;
          });
          // if (snap[version]) {
          //   snap[version].map((q) => {
          //     console.log(q);
          //   });
          // }
          let data = {
            step1: snap.step1,
            title: snap.title,
            intro: snap.intro,
            type: type,
            version: version,
            mt: mtmembers,
            questions: questions,
          };
          return data;
        } else {
          return false;
        }
        // this.props.getSingleAnalysis(snapshot.data())
      })
      .catch(function (error) {
        // console.log("Error getting documents: ", error);
      });
    if (res) return res;
    return false;
  };

  getLogs = () => {};

  createLead2Scale = async (data) => {
    // let survey = {
    //   name
    // }
    let date = new Date(moment().format());

    // console.log(data);
    data.surveys.map((s) => {
      let id = uuidv4();
      let enviroment =
        process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
      s.createAt = date;
      s.updatedAt = date;
      s.companyName = data.name;
      s.completionDate = null;
      s.id = id;
      s.code = Math.floor(Math.random() * 999999) + 1;
      // console.log(s);
      // this.db.collection(enviroment).doc(id).set(s);
      this.db
        .collection(enviroment)
        .doc(id)
        .set(s)
        .then((res) => {
          let obj = {
            createdAt: moment().toISOString(),
            message: `survey for ${data.name} is successfully entered into the database. User: ${s.name} - ${s.email}`,
            code: "success",
            error: false,
          };
          this.rtdb.ref(`logs/${data.name}`).push(obj);
          let link = encodeURI(
            `${process.env.REACT_APP_SERVER_URL}survey/${s.id}/${s.code}`
          );
          api.post(
            `/welcome/?email=${s.email}&link=${encodeURI(link)}&name=${
              s.name
            }&company=${s.companyName}`
          );
        })
        .catch((error) => {
          let obj = {
            createdAt: moment().toISOString(),
            message: `survey for ${data.name} is failed to create. User: ${s.name} - ${s.email}`,
            code: "error",
            error: error,
          };
          this.rtdb.ref(`logs/${data.name}`).push(obj);
          api.post(
            `/error/?email=${s.email}&name=${s.name}&company=${s.companyName}`
          );
        });
      // console.log(process.env.REACT_APP_SERVER_URL);
      // Send email to user
      let link = encodeURI(
        `${process.env.REACT_APP_SERVER_URL}survey/${s.id}/${s.code}`
      );
    });
    return 200;
  };

  createTemplate = async (data, id) => {
    let date = new Date(moment().format());
    // console.log(id);
    if (id) {
      // console.log("edit template");

      data.updatedAt = date;
      this.db.collection("templates/").doc(id).update(data);
    } else {
      // console.log("new template");
      let newId = uuidv4();
      data.createdAt = date;
      data.updatedAt = date;
      data.id = newId;
      this.db.collection("templates/").doc(newId).set(data);
    }

    return 200;
  };

  removeTemplate = async (e, id) => {
    e.stopPropagation();
    // console.log(id);
    this.db
      .collection("templates/")
      .doc(id)
      .update({ archived: true })
      .then(function () {
        // console.log("Document successfully archived!");
        return 200;
      })
      .catch(function (error) {
        // console.error("Error removing document: ", error);
        return 400;
      });
  };

  duplicateTemplate = async (e, id) => {
    e.stopPropagation();
    this.db
      .collection("templates/")
      .doc(id)
      .get()
      .then((doc) => {
        let date = new Date(moment().format());

        let data = doc.data();

        let newId = uuidv4();
        data.createdAt = date;
        data.updatedAt = date;
        data.id = newId;
        data.title = data.title + " (copy)";
        // console.log(data);
        this.db.collection("templates/").doc(newId).set(data);
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });

    // return 200;
  };
  // saveTemplate = async (data) => {
  //   let date = new Date(moment().format());
  //   data.updatedAt = date;
  //   this.db.collection("templates/").doc(data.id).set(data);

  //   return 200;
  // };

  getTemplates = async () => {
    let res = await this.db.collection("templates");
    let array = [];
    await res
      .get()
      .then((querySnapshot) => {
        //querySnapshot is "iteratable" itself
        querySnapshot.forEach((doc) => {
          //doc contains all metadata of Firestore object, such as reference and id
          if (!doc.data().archived) array.push(doc.data());
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return array;
  };

  getTemplate = async (id) => {
    let res = await this.db.collection("templates").doc(id);
    let data = null;
    await res
      .get()
      .then((doc) => {
        if (doc.exists) {
          data = doc.data();
          // array = d.analyses;
          // return d;
        } else {
          // console.log("template does not exist");
          // doc.data() will be undefined in this case
          // return [];
        }
      })
      .catch((error) => {
        return error;
      });
    return data;
  };

  getCompanyNames = async () => {
    let res = await this.db.collection("surveys");
    let array = [];
    await res
      .get()
      .then((querySnapshot) => {
        //querySnapshot is "iteratable" itself
        querySnapshot.forEach((doc) => {
          //doc contains all metadata of Firestore object, such as reference and id
          // console.log(doc.data().companyName);
          let data = doc.data();
          let date = data.createAt.toDate();
          let search = doc.data().companyName.toLowerCase();
          let companyName = doc.data().companyName;
          data.createAt = date;

          if (!array.includes(companyName)) {
            array.push(companyName);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });

    return array;
  };

  getSurveys = async () => {
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    let res = await this.db.collection(enviroment);
    let array = [];
    await res
      .get()
      .then((querySnapshot) => {
        //querySnapshot is "iteratable" itself
        querySnapshot.forEach((doc) => {
          //doc contains all metadata of Firestore object, such as reference and id

          let data = doc.data();

          data.createAt = data.createAt.toDate();
          data.updatedAt = data.updatedAt.toDate();

          if (data.completionDate)
            data.completionDate = data.completionDate.toDate();

          if (data.progress === 100 && !data.completionDate) {
            data.completionDate = data.updatedAt;
          }
          array.push(data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    return array;
  };

  getSurvey = async (id) => {
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    let res = await this.db
      .collection(enviroment)
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          let data = snapshot.data();
          data.createdAt = data.createAt.toDate();
          data.updatedAt = data.updatedAt.toDate();
          if (data.completionDate)
            data.completionDate = data.completionDate.toDate();
          return data;
        } else {
          return false;
        }
        // this.props.getSingleAnalysis(snapshot.data())
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    // console.log(res);
    return res;
  };

  answerQuestion = async (id, question, answer, index) => {
    let mt = false;
    let questionId = question.id;
    if (question.mt) mt = true;

    let array = [];
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    let survey = await this.db
      .collection(enviroment)
      .doc(id)
      .get()
      .then((doc) => {
        let d = doc.data();
        let date = new Date(moment().format());
        d.updatedAt = date;
        if (!mt) {
          d.survey.questions.filter((s) => {
            if (s.id === questionId) {
              s.options.map((o, i) => {
                if (i === index) o.answer = answer;
              });
            }
            return s;
          });
        } else {
          d.survey.mt.filter((mt) => {
            if (mt.name === question.mtMember) {
              mt.questions.filter((s) => {
                if (s.id === questionId) {
                  s.options.map((o, i) => {
                    console.log(o, i, index);
                    if (i === index) o.answer = answer;
                  });
                }
                return s;
              });
            }
          });
          // d.survey.questions.filter((s) => {
          //   if (s.id === questionId) {
          //     s.options.map((o, i) => {
          //       console.log(o, i, index);
          //       if (i === index) o.answer = answer;
          //     });
          //     s.answer = answer;
          //   }
          //   return s;
          // });
        }
        return d;
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    if (survey) {
      // let enviroment = process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";

      this.db.collection(enviroment).doc(id).set(survey);
    }
  };

  skipMt = async (index, id) => {
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    let survey = await this.db
      .collection(enviroment)
      .doc(id)
      .get()
      .then((doc) => {
        let d = doc.data();
        // console.log(d);
        let date = new Date(moment().format());
        d.updatedAt = date;

        d.survey.mt = d.survey.mt.filter((mt, i) => {
          if (i === index) {
            mt.skip = true;
          }
          return mt;
        });
        return d;
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    if (survey) {
      this.db.collection(enviroment).doc(id).set(survey);
    }
  };

  saveProgress = async (percentage, id) => {
    // console.log("saving progress");
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    this.db.collection(enviroment).doc(id).update({ progress: percentage });
  };

  getSurveyFromArray = async (array) => {
    let surveys = [];
    for (let i = 0; i < array.length; i++) {
      let survey = await this.getSurveyForCalculation(array[i]);
      surveys.push(survey);
    }
    return surveys;
  };

  getSurveyForCalculation = async (id) => {
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    let res = await this.db
      .collection(enviroment)
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          let data = snapshot.data();
          return data;
        } else {
          return false;
        }
        // this.props.getSingleAnalysis(snapshot.data())
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    // console.log(res);
    return res;
  };

  saveSurvey = (id) => {
    let date = new Date(moment().format());
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    this.db
      .collection(enviroment)
      .doc(id)
      .update({ initialSurvey: false, updatedAt: date });
  };

  finishSurvey = (id) => {
    let date = new Date(moment().format());
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    this.db
      .collection(enviroment)
      .doc(id)
      .update({ completionDate: date, updatedAt: date, progress: 100 });
  };

  singleLead2Scale = (id) => {
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    return this.db.collection(enviroment).doc(id);
  };

  getPercentage = (questions, mt, initial) => {
    let allQuestions = [];
    let i = 0;
    let answered = 0;

    questions.map((m) => {
      m.options.map((o) => {
        i++;
        o.id = i;
        let data = {
          index: i,
          done: o.answer ? true : false,
          type: "general",
        };
        if (data.done) answered++;
        allQuestions.push(data);

        return m;
      });
    });
    mt.map((m, a) => {
      if (!m.skip) {
        m.questions.map((q) => {
          q.mtmember = m.name;
          // allQuestions.push(q);
          q.options.map((o) => {
            i++;
            o.id = i;
            o.mtId = a;
            let data = {
              index: i,
              done: o.answer ? true : false,
              type: "mt",
            };
            if (data.done) answered++;

            allQuestions.push(data);
            return q;
          });
        });
      }
    });
    // console.log(answered, allQuestions.length);
    let percentage = (answered / allQuestions.length) * 100;
    percentage = Number(percentage.toFixed(0));
    // console.log(percentage);
    return percentage;
  };

  saveAnalyses = async (data, analyses) => {
    let uid = uuidv4();
    analyses.push(uid);
    this.db.collection("analyses/").doc(uid).set(data);
    this.db
      .collection("companies/")
      .doc(data.navigator.company)
      .set({ analyses: analyses });

    data.uid = uid;
    api.post("/", { data }).then((res) => {
      // console.log(res.data);
    });
  };

  getExistingAnalyses = async (company) => {
    let res = await this.db.collection("companies/").doc(company);
    let array = [];
    await res
      .get()
      .then((doc) => {
        if (doc.exists) {
          let d = doc.data();
          array = d.analyses;
          return d;
        } else {
          // doc.data() will be undefined in this case
          // return [];
        }
      })
      .catch((error) => {
        return error;
      });
    return array;
  };
  analyses = () => this.db.collection("analyses");
  logs = (company) => this.rtdb.ref(`/logs/${company}`).once("value");
  database = () => this.db;

  sendReminder = async (data) => {
    // console.log(data);
    let link = `${process.env.REACT_APP_SERVER_URL}survey/${data.id}/${data.code}`;

    let resp = await api
      .post(
        `/reminder/?email=${data.email}&link=${encodeURI(link)}&name=${
          data.name
        }&company=${data.companyName}`
      )
      .then((res) => {
        return res;
      });
    return resp;
  };
}

export default Firebase;
