import React from "react";
import { Input, Label, FormGroup, Form } from "reactstrap";
import EditableLabel from "react-inline-editing";

export const Rating = ({ data, answerQuestion, mt }) => {
  return (
    <div className="question-field rating_multiple">
      {data.options.map((o, i) => {
        return (
          <Form key={i} className={`${o.answer ? "" : "empty"}`}>
            <FormGroup
              tag="fieldset"
              onChange={(e) => answerQuestion(e, data, i)}
            >
              {o.left !== "null" ? (
                <FormGroup className="text right bigger" check>
                  <p>{o.left}</p>
                </FormGroup>
              ) : null}

              <FormGroup check>
                <Label check>
                  1
                  <Input
                    defaultChecked={o.answer == 1 ? true : false}
                    type="radio"
                    value={1}
                    name="answer"
                    name="radio1"
                  />
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  2
                  <Input
                    defaultChecked={o.answer == 2 ? true : false}
                    type="radio"
                    value={2}
                    name="answer"
                    name="radio1"
                  />
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  3
                  <Input
                    type="radio"
                    defaultChecked={o.answer == 3 ? true : false}
                    value={3}
                    name="answer"
                    name="radio1"
                  />
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  4
                  <Input
                    type="radio"
                    defaultChecked={o.answer == 4 ? true : false}
                    value={4}
                    name="answer"
                    name="radio1"
                  />
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  5
                  <Input
                    type="radio"
                    defaultChecked={o.answer == 5 ? true : false}
                    value={5}
                    name="answer"
                    name="radio1"
                  />
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  6
                  <Input
                    type="radio"
                    defaultChecked={o.answer == 6 ? true : false}
                    value={6}
                    name="answer"
                    name="radio1"
                  />
                </Label>
              </FormGroup>

              {o.right !== "null" ? (
                <FormGroup className="text right bigger" check>
                  <p>{o.right}</p>
                </FormGroup>
              ) : null}
            </FormGroup>
          </Form>
        );
      })}
    </div>
  );
};
export const RatingExample = (data, handleFocus) => {
  return (
    <div className="question-field rating_multiple">
      {data.data.options.map((o, i) => {
        return (
          <FormGroup key={i} tag="fieldset">
            <FormGroup className="text left" check>
              <p>{o.left}</p>
            </FormGroup>
            <FormGroup check>
              <Label check>
                1
                <Input disabled type="radio" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                2
                <Input disabled type="radio" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                3
                <Input disabled type="radio" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                4
                <Input disabled type="radio" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                5
                <Input disabled type="radio" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                6
                <Input disabled type="radio" />
              </Label>
            </FormGroup>
            <FormGroup className="text right" check>
              <p>{o.right}</p>
            </FormGroup>
          </FormGroup>
        );
      })}
    </div>
  );
};

export const RatingMultiple = ({ data, answerQuestion }) => {
  return (
    <div className="question-field rating_multiple">
      <FormGroup tag="fieldset" onChange={(e) => answerQuestion(e, data)}>
        <FormGroup className="text left" check>
          <p>left text</p>
        </FormGroup>
        <FormGroup check>
          <Label check>
            1
            <Input type="radio" value={1} name="answer" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            2
            <Input type="radio" value={2} name="answer" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            3
            <Input type="radio" value={3} name="answer" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            4
            <Input type="radio" value={4} name="answer" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            5
            <Input type="radio" value={5} name="answer" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            6
            <Input type="radio" value={6} name="answer" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup className="text right" check>
          <p>right text</p>
        </FormGroup>
      </FormGroup>
    </div>
  );
};

export const RatingSingle = ({ data, answerQuestion }) => {
  return (
    <div className="question-field rating_single">
      <FormGroup tag="fieldset" onChange={(e) => answerQuestion(e, data)}>
        <FormGroup className="text left" check>
          <p>left text</p>
        </FormGroup>
        <FormGroup check>
          <Label check>
            1
            <Input type="radio" value={1} name="value" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            2
            <Input type="radio" value={2} name="value" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            3
            <Input type="radio" value={3} name="value" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            4
            <Input type="radio" value={4} name="value" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            5
            <Input type="radio" value={5} name="value" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            6
            <Input type="radio" value={6} name="value" name="radio1" />
          </Label>
        </FormGroup>
        <FormGroup className="text right" check>
          <p>naiwdbw bd wiabiwabid ab bbdwba </p>
        </FormGroup>
      </FormGroup>
    </div>
  );
};
