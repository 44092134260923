import { LOGIN, LOGOUT, LOGIN_ERROR, GET_COMPANIES } from "./types";

import axios from "axios";
import { baseUrl } from "../lib/constants";
import { loadingToggle } from "./loading";

export const loginAction = (payload) => (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: payload,
  });
};

export const loginError = (payload) => (dispatch) => {
  dispatch({
    type: LOGIN_ERROR,
    payload: payload,
  });
  setTimeout(() => {
    dispatch({
      type: LOGIN_ERROR,
      payload: null,
    });
  }, 6000);
};

export const logoutAction = (cookies) => (dispatch) => {
  cookies.remove("authToken", { path: "/" });
  localStorage.removeItem("user");
  dispatch({
    type: LOGOUT,
  });
};

export const loadAuth = (cookies) => async (dispatch) => {
  dispatch(loadingToggle("auth", true));
  // console.log("loading auth", cookies.get("authToken"));

  if (localStorage.getItem("user")) {
    // const resp = await axios.get(`${baseUrl}/userprofile`, {
    //   headers: {
    //     Authorization: `Bearer ${cookies.get("authToken")}`,
    //   },
    // });
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    dispatch({
      type: "LOGIN",
      payload: loggedInUser,
    });
    dispatch(loadingToggle("auth", false));
  }
};

export const getCompanies = (cookies) => async (dispatch) => {
  const resp = await axios.get(
    `https://scaling-priorities.herokuapp.com/api/search`,
    {
      headers: {
        Authorization: `Bearer ${cookies.get("authToken")}`,
      },
    }
  );
  let arr = resp.data.people;
  arr.map((a) => {
    a.completionDate = a.surveys[0].completionDate;
  });
  dispatch({
    type: "GET_COMPANIES",
    payload: arr,
  });
};
