import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import board from "../../assets/lead2scale/board.png";
import circular from "../../assets/lead2scale/circular.png";
import flight from "../../assets/lead2scale/flight.png";
import food from "../../assets/lead2scale/food.png";
import {
  InputGroup,
  Input,
  Button,
  Label,
  FormGroup,
  CustomInput,
} from "reactstrap";

// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

// import Redux Actions
import { searchNavigator, clearSearch } from "../../actions/analyses";

// Import components
import SearchBar from "./components/searchBar";

// Import Constants
import { NavigatorReportQuestions } from "../../lib/constants";

// Import Icons
import {
  faCheckCircle,
  faTimesCircle,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import Form from "reactstrap/lib/Form";

class NewScale extends React.Component {
  _initial_state = {
    existingAnalyses: [],
    q: "",
    searchList: [],
    company: "",
    companyMT: "",
    internationalisation: {},
    mt: [],
    step: 1,
    companyForm: {
      name: "",
      role: null,
    },
    employees: [],
    employeeForm: {
      name: "",
      email: "",
    },
    selectedIndustry: "",
    internationalForm: {
      local: 100,
      international: 0,
    },
    survey: null,
    templates: [],
  };

  constructor() {
    super();

    this.state = this._initial_state;
  }

  componentDidMount = async () => {
    let templates = await this.props.firebase.getTemplates();
    this.setState({
      templates,
    });
    this.getCompanyNames();
    if (this.props.location.search.length > 0) {
      let data = JSON.parse(
        new URLSearchParams(this.props.location.search).get("data")
      );
      let selectedTemplate = templates.filter((t) => t.id === data.templateId);

      this.addExistingData(data);
      this.chooseTemplate(selectedTemplate[0]);
    }
  };

  addExistingData = (data) => {
    this.setState({
      mt: data.mt,
      company: data.company,
      step: data.step,
    });
  };
  getCompanyNames = async () => {
    let names = await this.props.firebase.getCompanyNames();
    this.setState({
      companyList: names,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.step !== this.state.step) {
    //   console.log(this.state);
    // }
  }
  changeInternational = (e) => {
    // console.log(e.target.value);
    if (e.target.id === "local") {
      this.setState({
        internationalForm: {
          local: Number(e.target.value),
          international: 100 - e.target.value,
        },
      });
    } else {
      this.setState({
        internationalForm: {
          local: 100 - e.target.value,
          international: Number(e.target.value),
        },
      });
    }
  };
  saveInternational = () => {
    this.setState({
      step: 4,
      internationalisation: {
        local: this.state.internationalForm.local,
        international: this.state.internationalForm.international,
      },
    });
  };

  saveCompany = (e) => {
    this.setState({
      step: 3,
      companyMT: this.state.companyForm,
    });
  };

  isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  };

  searchAnalyses = (e) => {
    let query = e.target.value;
    this.setState({
      q: query,
    });

    this.searchCompany(query, this.state.companyList);

    // this.props.searchNavigator(
    //   query,
    //   this.props.cookies,
    //   this.props.navigator.companies
    // );
  };

  searchCompany = (query, list) => {
    let search = query.toLowerCase();
    // let newState = [];

    // if (list.includes(query)) {
    //   newState.push(person);
    // }
    // console.log(query, search, list);
    // console.log(list);
    if (query.length > 2) {
      const regexp = new RegExp(query, "i");
      let newState = list.filter((x) => regexp.test(x));
      this.setState({ searchList: newState });
    } else {
      this.setState({ searchList: [] });
    }
  };

  getExistingAnalysis = async (company) => {
    let res = await this.props.firebase.getExistingAnalyses(company);

    this.setState({
      existingAnalyses: res,
    });
  };

  addNew = (company) => {
    this.setState({
      company: company,
      q: "",
      searchList: [],
      step: 2,
    });
  };

  chooseTemplate = async (data) => {
    // console.log(data);
    let d = {
      intro: data.intro,
      outro: data.outro,
      notDoneText: data.notDoneText,
      templateId: data.id,
      title: data.title,
      type: data.type,
      questions: data.questions,
      version: data.version,
    };

    let mt = this.state.mt.map((m) => {
      m.questions = data.mtQuestions;
      return m;
    });
    d.mt = mt;
    // console.log(d);
    this.setState({
      selectedIndustry: data.id,
      template: d,
    });
  };

  componentWillUnmount() {
    this.props.clearSearch();
  }

  handleEmployee = (e) => {
    // let target = this.state.employeeForm[e.target.id];
    // console.log(target)
    // this.setState({
    //   ...this.state.employeeForm,
    //   [target]: e.target.value,
    // });

    let employeeForm = { ...this.state.employeeForm };
    employeeForm[e.target.id] = e.target.value;
    this.setState({ employeeForm });
  };

  submitEmployees = () => {
    this.setState({
      step: 5,
    });
  };
  submitMT = () => {
    this.setState({
      step: 3,
    });
  };

  AddEmployee = () => {
    let data = this.state.employeeForm;
    let state = this.state.employees;

    if (data.name.length > 2) {
      state.push(data);

      this.setState({
        employeeForm: {
          name: "",
          email: "",
        },
        employees: state,
      });
    }
  };

  handleMT = (e) => {
    let companyForm = { ...this.state.companyForm };
    companyForm.name = e.target.value;
    this.setState({ companyForm });
  };

  handleDropDown = (e) => {
    let companyForm = { ...this.state.companyForm };
    companyForm.role = e.target.value;
    this.setState({ companyForm });
  };

  AddMT = () => {
    let data = this.state.companyForm;
    let state = this.state.mt;

    if (data.name.length > 2) {
      state.push(data);

      this.setState({
        companyForm: {
          name: "",
          role: "",
        },
        mt: state,
      });
    }
  };

  removeEmployee = (a) => {
    let state = this.state.employees;
    state = state.filter((s, i) => i !== a);
    this.setState({
      employees: state,
    });
  };
  removeMT = (a) => {
    let state = this.state.mt;
    state = state.filter((s, i) => i !== a);
    this.setState({
      mt: state,
    });
  };

  createSurvey = async () => {
    let array = [];
    this.state.employees.map((e) => {
      e.survey = this.state.template;
      e.progress = 0;
      array.push(e);
    });
    let data = {
      name: this.state.company,
      templateId: this.state.selectedIndustry,
      surveys: array,
    };

    // console.log(data);
    let result = await this.props.firebase.createLead2Scale(data);
    this.props.history.push("/");
  };

  render() {
    const {
      company,
      internationalisation,
      lead2scale,
      q,
      step,
      employees,
      companyForm,
      mt,
      selectedIndustry,
      companyMT,
      employeeForm,
    } = this.state;
    // console.log(this.state);

    return (
      <div className="new-analysis">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div
                className="back-button"
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back
              </div>
              <h1>Create a new survey</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div className="checklist">
                <ul>
                  <li>
                    {_.isEmpty(company) ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    ) : (
                      <span className="icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    )}
                    Add Company
                  </li>
                  <li>
                    {!_.isEmpty(mt) && step > 2 ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    ) : (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    )}
                    Add MT members
                  </li>
                  <li>
                    {!_.isEmpty(selectedIndustry) && step > 3 ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    ) : (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    )}
                    Choose Survey Template
                  </li>
                  <li>
                    {!_.isEmpty(employees) && step === 5 ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    ) : (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    )}
                    Add Employees
                  </li>
                  <li>
                    {!_.isEmpty(employees) && step === 5 ? (
                      <span className="icon">
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                    ) : (
                      <span className="icon">
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </span>
                    )}
                    Overview
                  </li>
                </ul>
              </div>
              {step === 1 ? (
                <>
                  <>
                    <div className="search-bar">
                      <h2>Search or add a company</h2>

                      <SearchBar
                        search={this.searchAnalyses}
                        suggestions={this.state.searchList}
                        getNavigatorData={this.getNavigatorData}
                        addNew={this.addNew}
                        query={q}
                      />
                    </div>
                  </>
                </>
              ) : null}

              {step === 2 ? (
                <>
                  <h2>Fill in the company MT members</h2>
                  <div className="form employees">
                    <div className="input-block">
                      <FormGroup>
                        <Input
                          onChange={(e) => this.handleMT(e)}
                          value={companyForm.name}
                          id="name"
                          name="name"
                          placeholder="MT member Name"
                          type="text"
                        />

                        <Input
                          type="select"
                          name="role"
                          id="role"
                          defaultValue={"null"}
                          onChange={(e) => this.handleDropDown(e)}
                        >
                          <option disabled value="null">
                            Select MT member Role
                          </option>
                          <option value="Founder">Founder</option>
                          <option value="CEO">CEO</option>
                          <option value="CFO">CFO</option>
                          <option value="COO">COO</option>
                          <option value="CTO">CTO</option>
                          <option value="COO">CMO</option>
                          <option value="President">President</option>
                          <option value="Vice President">Vice President</option>
                          <option value="Owner">Owner</option>
                          <option value="Executive Assistant">
                            Executive Assistant
                          </option>
                          <option value="Other">Other</option>
                        </Input>
                      </FormGroup>
                    </div>

                    <Button
                      onClick={() => this.setState({ step: step - 1 })}
                      color="secondary"
                    >
                      Back
                    </Button>

                    {mt.length > 0 ? (
                      <>
                        <Button
                          onClick={() => this.submitMT()}
                          style={{ float: "right" }}
                          color="primary"
                        >
                          Submit
                        </Button>

                        <Button
                          onClick={() => this.AddMT()}
                          style={{ float: "right", marginRight: "10px" }}
                          color="primary"
                        >
                          Add extra MT Member
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => this.AddMT()}
                          style={{ float: "right" }}
                          color="primary"
                        >
                          Add MT Member
                        </Button>
                      </>
                    )}
                  </div>
                  {mt.length > 0 ? (
                    <div className="selected-employees">
                      <ul>
                        {mt.map((e, i) => {
                          return (
                            <li key={i} className="employee">
                              <span
                                onClick={() => this.removeMT(i)}
                                className="icon remove"
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </span>
                              <span className="name">
                                {e.name} - {e.role}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </>
              ) : null}

              {step === 3 ? (
                <>
                  <h2>Choose survey template</h2>
                  <div className="container surveys">
                    <div className="row mb-4">
                      {this.state.templates.map((s, i) => {
                        if (s.type === "lead2scale") {
                          return (
                            <div
                              key={i}
                              className="col col-4 select-template"
                              onClick={() => this.chooseTemplate(s)}
                            >
                              {selectedIndustry === s.id ? (
                                <span className="img-icon">
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                </span>
                              ) : null}
                              <div className="widget survey-item">
                                <div className="widget-title templates">
                                  <div>
                                    {s.title}
                                    <br />
                                    <span className="text-link">
                                      Select template
                                    </span>
                                  </div>
                                </div>
                                <div className="details">
                                  <p>Type: {s.type}</p>
                                  <p>Report version: {s.version}</p>
                                  <p># Questions: {s.questions.length}</p>
                                  <p># MT Questions: {s.mtQuestions.length}</p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <Button
                      onClick={() => this.setState({ step: step - 1 })}
                      color="secondary"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => this.saveInternational()}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : null}

              {step === 4 ? (
                <>
                  <h2>Who's gonna fill in the survey</h2>
                  <div className="form employees">
                    <div className="input-block">
                      <FormGroup>
                        <Input
                          onChange={(e) => this.handleEmployee(e)}
                          value={employeeForm.name}
                          id="name"
                          name="name"
                          placeholder="Employee Name"
                          type="text"
                        />
                        <Input
                          onChange={(e) => this.handleEmployee(e)}
                          value={employeeForm.email}
                          id="email"
                          name="email"
                          placeholder="Employee e-mail"
                          type="text"
                        />
                      </FormGroup>
                    </div>

                    <Button
                      onClick={() => this.setState({ step: step - 1 })}
                      color="secondary"
                    >
                      Back
                    </Button>

                    {employees.length > 0 ? (
                      <>
                        <Button
                          onClick={() => this.submitEmployees()}
                          style={{ float: "right" }}
                          color="primary"
                        >
                          Submit
                        </Button>

                        <Button
                          onClick={() => this.AddEmployee()}
                          style={{ float: "right", marginRight: "10px" }}
                          color="primary"
                        >
                          Add extra employee
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => this.AddEmployee()}
                          style={{ float: "right" }}
                          color="primary"
                        >
                          Add employee
                        </Button>
                      </>
                    )}
                  </div>
                  {employees.length > 0 ? (
                    <div className="selected-employees">
                      <ul>
                        {employees.map((e, i) => {
                          return (
                            <li key={i} className="employee">
                              <span
                                onClick={() => this.removeEmployee(i)}
                                className="icon remove"
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </span>
                              <span className="name">{e.name}</span>
                              <span className="email">{e.email}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </>
              ) : null}

              {step === 5 ? (
                <>
                  <h2>Overview</h2>

                  <div className="content-block">
                    <div className="company">
                      <h2>Company name</h2>
                      <h3>{this.state.company}</h3>
                    </div>

                    <div className="mt-members">
                      <h2>MT Members</h2>
                      {mt.map((m, i) => {
                        return (
                          <span key={i}>
                            {m.name} - {m.name}
                          </span>
                        );
                      })}
                    </div>

                    <div className="industry">
                      <h2>Program</h2>
                      {selectedIndustry}
                    </div>

                    <div className="employees">
                      <h2>
                        Employees <small>(survey takers)</small>
                      </h2>
                      {employees.map((m, i) => {
                        return (
                          <span key={i}>
                            {m.name} - {m.email}{" "}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    onClick={() => this.createSurvey()}
                    className="create button"
                  >
                    Create Survey
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyses, loading, navigator }) => {
  return {
    analyses,
    loading,
    navigator,
  };
};

export default connect(mapStateToProps, {
  searchNavigator,
  clearSearch,
})(withFirebase(withRouter(withCookies(NewScale))));
