import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
// import Redux Actions

import { searchNavigator, clearSearch } from "../../actions/analyses";
import { loadingToggle, showNotice } from "../../actions/loading";
import { getSurveys } from "../../actions/surveys";

import "../../scss/dashboard.scss";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "../css/normalize.css";
import "../css/menu_cornermorph.css";
import { isObject } from "reactstrap/lib/utils";

class Lead2ScaleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      menu: false,
      showDetails: false,
      popupData: null,
      view: [],
      logs: [],
    };
  }
  componentDidMount = async () => {
    this.getSurveys();
    this.props.loadingToggle("surveys", true);
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount() {
    // console.log("unmount");

    this.surveys = null;
  }

  toggleMenu() {
    this.setState({
      menu: this.state.menu ? false : true,
    });
  }
  camelize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  getSurveys = async (ids, name) => {
    let surveys = await this.props.firebase.getSurveys();

    surveys = surveys.filter((s) => s.survey.type === "lead2scale");
    // surveys
    surveys.map((s) => {
      // console.log(s);
      s.companyName = this.camelize(s.companyName);
      s.group = this.camelize(s.companyName);
      return s;
    });
    let grouped = _.chain(surveys)
      // Group the elements of Array based on `color` property
      .groupBy("group")
      // `key` is group's name (color), `value` is the array of objects
      .map((value, key) => ({ company: key, surveys: value }))
      .value();

    grouped.map((g) => {
      g.done = 0;
      g.total = 0;

      g.surveys.map((s) => {
        let percentage = this.props.firebase.getPercentage(
          s.survey.questions,
          s.survey.mt
        );
        g.total++;
        if (s.completionDate || s.progress === 100) g.done++;
      });
      return g;
    });
    // console.log(grouped);
    this.props.getSurveys(grouped);
  };

  goTo = (type) => {
    if (type === 1) {
      this.props.history.push("/create/analysis");
    }
    if (type === 2) {
      this.props.history.push("/create/lead2scale");
    }
  };

  showData = (data) => {
    this.setState({
      popupData: data,
    });
  };

  showLogs = async (data) => {
    console.log(data.company);
    let array = [];
    let snapshot = await this.props.firebase.logs(data.company);
    let result = await snapshot.val();
    Object.keys(result).map((d) => {
      result[d].id = d;
      array.push(result[d]);
    });
    console.log(array);

    // this.setState({
    //   popupData: data,
    // });
  };

  handleInputChange(event) {
    let value = event.target.value;
    let oldState = this.state.view;
    if (!oldState.includes(value)) {
      oldState.push(value);
    } else {
      const index = oldState.indexOf(value);
      if (index > -1) {
        oldState.splice(index, 1);
      }
    }
    this.setState({
      view: oldState,
    });
  }
  checkAll = (e, data) => {
    let array = [];
    if (e.target.checked) {
      data.map((a) => {
        if (a.progress == 100) array.push(a.id);
      });
    }
    this.setState({
      view: array,
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.view);
    let url = this.state.view.join("&");
    this.props.history.push("/view/lead2scale/" + url);
  };

  addNewUser = (survey) => {
    // console.log("add new user to existing survey");
    // console.log(survey);
    let surveyId = survey.surveys[0].survey.templateId;
    let mt = [];
    survey.surveys.map((s) => {
      // console.log(s)
      s.survey.mt.map((m) => {
        delete m.questions;
        if (m.skip) delete m.skip;

        if (!_.find(mt, m)) {
          mt.push(m);
        }
      });
    });
    let data = {
      company: survey.company,
      mt: mt,
      step: 4,
      templateId: surveyId,
    };
    console.log(data);
    let encoded = JSON.stringify(data);
    // console.log(encoded);

    this.props.history.push("/create/lead2scale?data=" + encodeURI(encoded));
  };

  sendEmail = async (data) => {
    let resp = await this.props.firebase.sendReminder(data);
    // console.log(resp);
    if (resp) {
      this.props.showNotice("Reminder send successfully");
    }
  };
  remindUser = (data) => {
    // console.log("remind", data);
    if (data.progress < 100) {
      confirmAlert({
        // title: "What do you want to do?",
        message: `What do you want to do?`,
        buttons: [
          {
            label: "Send Reminder",
            onClick: () => this.sendEmail(data),
          },
          {
            label: "Lookup data (coming soon)",
            onClick: () => console.log("Lookup data"),
          },
        ],
      });
    } else {
      confirmAlert({
        // title: "What do you want to do?",
        message: `What do you want to do?`,
        buttons: [
          {
            label: "Lookup data (coming soon)",
            onClick: () => console.log("Lookup data"),
          },
        ],
      });
    }
  };

  render() {
    const { surveys } = this.props;
    const { popupData, view } = this.state;

    return (
      <div className={`new-analysis ${this.state.menu ? "show-menu" : null}`}>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <h1>Survey Dashboard</h1>
              <div className="create">
                <div
                  className="create-button"
                  id="open-button"
                  onClick={(e) => this.toggleMenu()}
                  // onClick={() => this.props.history.push("/create/analysis")}
                >
                  <FontAwesomeIcon icon={faPlusSquare} />
                </div>
                <div className="menu-wrap">
                  <nav className="menu">
                    <div className="link-list">
                      {/* <span onClick={() => this.goTo(1)}>
                        Create new analysis
                      </span> */}
                      <span onClick={() => this.goTo(2)}>
                        Create Lead2Scale survey
                      </span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container surveys">
          <div className="row">
            {surveys.payload.length > 0 ? (
              surveys.payload.map((s, i) => {
                // console.log(s, i);
                return (
                  <div
                    key={i}
                    className="col col-md-6 col-lg-4 col-sm-6 col-xs-6"
                  >
                    <div className="widget survey-item">
                      <div className="widget-title dashboard">
                        <span className="number">
                          <span className="head">done</span>
                          {s.done}
                        </span>
                        <span className="title">{s.company}</span>
                        <span className="number">
                          <span className="head">total</span>
                          {s.total}
                        </span>

                        {/* <span
                        onClick={() =>
                          // this.props.history.push(`/survey/${a.id}`)
                          this.setState({ test: "test" })
                        }
                        className="text-link"
                      >
                        View details
                      </span> */}
                      </div>
                      <div className="widget-box">
                        <ul className="list">
                          {s.surveys.map((a, i) => {
                            // console.log(a);
                            return (
                              <li
                                key={i}
                                onClick={
                                  () => this.remindUser(a)
                                  // this.setState({ test: "test" })
                                }
                                className="item remindable"
                              >
                                <p>
                                  <b>Name:</b> {a.name}
                                </p>
                                <p>
                                  <b>E-mail:</b> {a.email}
                                </p>
                                <p>
                                  <b>Progress:</b> {a.progress}%
                                </p>
                                <p className="created-at">
                                  <b>Created At:</b>{" "}
                                  {moment(new Date(a.createAt)).fromNow()}{" "}
                                </p>
                                {a.completionDate || a.progress === 100 ? (
                                  <>
                                    <span className="icon completion">
                                      <FontAwesomeIcon icon={faCheckCircle} />
                                    </span>
                                    <p className="created-at">
                                      <b>Completed At:</b>{" "}
                                      {moment(
                                        new Date(a.completionDate)
                                      ).fromNow()}{" "}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <span className="icon completion">
                                      <FontAwesomeIcon icon={faTimesCircle} />
                                    </span>
                                    <p className="created-at">
                                      <b>Updated At:</b>{" "}
                                      {moment(new Date(a.updatedAt)).fromNow()}{" "}
                                    </p>
                                  </>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                        <div className="button-item">
                          <div
                            className="generate"
                            onClick={() => this.addNewUser(s)}
                          >
                            Add new user
                          </div>
                          <div
                            className="view"
                            onClick={() => this.showData(s)}
                          >
                            view
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="link-text" onClick={() => this.toggleMenu()}>
                Create your first survey
              </div>
            )}
          </div>
        </div>
        <div
          className="popup-container"
          style={{ display: this.state.popupData ? "flex" : "none" }}
        >
          <div className="content">
            <span
              className="close-button"
              onClick={() =>
                this.setState({
                  popupData: null,
                  view: [],
                })
              }
            >
              X
            </span>
            <p className="popup-title">Select surveys to include</p>
            <p>Selected: {view.length}</p>
            <Form onSubmit={this.onFormSubmit}>
              <FormGroup className="header" check>
                <Label className="list-field" check>
                  <span>
                    <Input
                      checked={view.length > 0 ? true : false}
                      type="checkbox"
                      onChange={(e) => this.checkAll(e, popupData.surveys)}
                    />
                  </span>
                  <span>Name</span>
                  <span>Progress</span>
                </Label>
              </FormGroup>
              <div className="input-list">
                {popupData
                  ? popupData.surveys.map((a, i) => {
                      if (a.progress == 100) {
                        return (
                          <FormGroup key={i} check>
                            <Label className="list-field" check>
                              <span>
                                <Input
                                  checked={view.includes(a.id) ? true : false}
                                  value={a.id}
                                  type="checkbox"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </span>
                              <span>{a.name}</span>
                              <span>{a.progress}%</span>
                            </Label>
                          </FormGroup>
                        );
                      }
                    })
                  : null}
              </div>
              <Button type="submit">Submit</Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyses, loading, navigator, surveys }) => {
  return {
    analyses,
    loading,
    navigator,
    surveys,
  };
};

export default connect(mapStateToProps, {
  searchNavigator,
  clearSearch,
  getSurveys,
  loadingToggle,
  showNotice,
})(withFirebase(withRouter(withCookies(Lead2ScaleList))));
