import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Single from "./pages/singleAnalysis";
import NewAnalysis from "./pages/newAnalysis";
import NewScale from "./pages/lead2scale";
import Lead2ScaleList from "./pages/lead2scale/list";
import FormEdit from "./pages/lead2scale/form";
import View from "./pages/lead2scale/view";
import Survey from "./pages/survey";
import TemplateAdd from "./pages/templates/add";
import Templates from "./pages/templates/";
import Login from "./pages/auth/login";
import Navigation from "./components/Navigation";
import { useAuth0 } from "@auth0/auth0-react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import _ from "lodash";
import { loadAuth, getCompanies } from "./actions/auth";

class App extends React.Component {
  componentDidMount() {
    this.props.loadAuth(this.props.cookies);
    // console.log(process.env.REACT_APP_ENV);
    // console.log(process.env.REACT_APP_SERVER_URL);

    // this.props.getCompanies(this.props.cookies);
  }

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div>
        <Router>
          {this.props.loading.notice.show ? <div className="notice" style={{display:"block"}}>{this.props.loading.notice.message}</div> : null}
          
          <Navigation auth={this.props.auth} />

          <Switch>
            <Route path="/" exact>
              {!_.isEmpty(this.props.auth.user) ? (
                <Lead2ScaleList />
              ) : (
                <Login />
              )}
            </Route>
            <Route exact path="/analyses/:id">
              {!_.isEmpty(this.props.auth.user) ? <Single /> : <Login />}
            </Route>
            <Route exact path="/create/analysis">
              {!_.isEmpty(this.props.auth.user) ? <NewAnalysis /> : <Login />}
            </Route>

            <Route exact path="/create/lead2scale">
              {!_.isEmpty(this.props.auth.user) ? <NewScale /> : <Login />}
            </Route>

            <Route exact path="/view/lead2scale/:id">
              {!_.isEmpty(this.props.auth.user) ? <View /> : <Login />}
            </Route>

            <Route exact path="/form/:id">
              {!_.isEmpty(this.props.auth.user) ? <FormEdit /> : <Login />}
            </Route>

            {/* Templates routes */}
            <Route exact path="/templates">
              {!_.isEmpty(this.props.auth.user) ? <Templates /> : <Login />}
            </Route>
            <Route exact path="/templates/new">
              {!_.isEmpty(this.props.auth.user) ? <TemplateAdd /> : <Login />}
            </Route>

            <Route exact path="/templates/edit/:id">
              {!_.isEmpty(this.props.auth.user) ? <TemplateAdd /> : <Login />}
            </Route>

            <Route exact path="/survey/:id/:code">
              <Survey />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, loading }) => {
  return {
    auth, loading,
  };
};
export default connect(mapStateToProps, { loadAuth, getCompanies })(
  withCookies(App)
);
