import { LOGIN, LOGOUT, SAVE_AUTH, LOGIN_ERROR } from "../actions/types";

const initialState = {
  user: {},
  error: null,
  profile: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return { ...state, user: payload, error: null };
    // return state;

    case LOGIN_ERROR:
      return { ...state, error: payload };

    case LOGOUT:
      return initialState;

    case SAVE_AUTH:
      return state;

    default:
      return state;
  }
};
