import {
  GET_ANALYSES,
  GET_SINGLE_ANALYSES,
  SEARCH_ANALYSES,
  CLEAR_SEARCH, SEARCH_NAVIGATOR
} from "../actions/types";

const initialState = {
  list: [],
  single: {},
  searchNav: {
    found: false,
    searching: false,
    list: [],
  },
  search: {
    found: false,
    searching: false,
    list: [],
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ANALYSES:
      return { ...state, list: payload };

    case GET_SINGLE_ANALYSES:
      return { ...state, single: payload };

    case SEARCH_ANALYSES:
      return { ...state, search: payload };

    case SEARCH_NAVIGATOR:
      return { ...state, searchNav: payload };

    case CLEAR_SEARCH:
      return { ...state, search: initialState.search };

    default:
      return state;
  }
};
