import t from "prop-types";
import React, { memo, useState } from "react";
import { Button, Form } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { auth0LoginPayload, auth0BaseUrl, baseUrl } from "../../lib/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { withFirebase } from "../../firebase";
import { loginAction, loginError } from "../../actions/auth";
const domain = process.env.REACT_APP_AUTH0_BASE_URL;

const handleLogin = (email, password, props) => {
  console.log(email, password, props);
  props.firebase.doSignInWithEmailAndPassword(email, password, props);
  // axios
  //   .post(`https://${auth0BaseUrl}/oauth/token`, {
  //     username: email,
  //     password,
  //     ...auth0LoginPayload,
  //   })
  //   .then(async (result) => {
  //     const resp = await axios.get(`${baseUrl}/userprofile`, {
  //       headers: {
  //         Authorization: `Bearer ${result.data.access_token}`,
  //       },
  //     });

  //     const loggedInUser = resp.data;

  //     props.loginAction(loggedInUser);
  //     props.cookies.set("authToken", result.data.access_token, { path: "/" });
  //     //   const resp = await request
  //     //     .get(`${baseUrl}/userprofile`)
  //     //     .set("Authorization", `Bearer ${result.body.access_token}`);
  //     //   const loggedInUser = resp.body;
  //     //   if (s.cookiePreference === true) {
  //     //     trackActivity(loggedInUser, "Logged In");
  //     //     identifyUser(loggedInUser, email);
  //     //   }
  //   })
  //   .catch((err) => {
  //     if (err && err.response) {
  //       props.loginError(err.response.data);
  //     }
  //   });
};

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setValueWith = (fn) => (e) => fn(e.target.value);
  const history = useHistory();

  return (
    <div className="form-block">
      <div className="logo-image">
        <img src={"https://cdn.shopify.com/s/files/1/0510/4638/3777/files/scaleupscan_logo_1728x.png?v=1605175256"} />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("submit");
          handleLogin(email, password, props);
        }}
      >
        <div className="form-group d-flex flex-column">
          <label className="control-label text-dark font-weight-bold" htmlFor="email">
            Email address
          </label>
          <input
            required
            className="form-control"
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={setValueWith(setEmail)}
          />
        </div>

        <div className="form-group d-flex flex-column pt-3">
          <label className="control-label text-dark font-weight-bold" htmlFor="password">
            Password
          </label>
          <input
            className="form-control"
            type="password"
            required
            value={password}
            onChange={setValueWith(setPassword)}
          />
        </div>

        <div className="text-center">
          <Button block type="submit">
            Login
          </Button>
        </div>
      </Form>
      {props.auth.error ? <div className="error">{props.auth.error.error_description}</div> : null}
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default connect(mapStateToProps, { loginAction, loginError })(withCookies(withFirebase(LoginForm)));
