import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
// import Redux Actions
import { searchNavigator, clearSearch } from "../../actions/analyses";
import { Input, CustomInput } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import QuestionsForm from "./components/questions";
import EditQuestionsForm from "./components/questions-edit";
import MtForm from "./components/mteam";
import EditMtForm from "./components/mteam-edit";
// Import Constants
import { RatingExample } from "../../lib/questionTypes";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../scss/dashboard.scss";

import "../css/normalize.css";
import "../css/menu_cornermorph.css";
import { questionCats } from "../../lib/constants";

class AddSurvey extends React.Component {
  _initial_state = {
    surveys: [],
    menu: false,
    intro: "",
    notDoneText: "",
    outro: "",
    id: null,
    type: "",
    showCode: false,
    mtmembers: false,
    templateName: "",
    mtQuestions: [],
    mtQuestion: {
      group: "null",
      type: "null",
      question: "",
      description: "",
      left: "",
      right: "",
      invert: false,
      options: [],
    },
    questions: [],

    question: {
      type: "null",
      group: "null",
      question: "",
      description: "",
      left: "",
      right: "",
      invert: false,
      options: [],
    },
    editorState: EditorState.createEmpty(),
    outroState: EditorState.createEmpty(),
    notDoneState: EditorState.createEmpty(),
    selectedEditor: null,
  };

  constructor(props) {
    super(props);

    this.state = this._initial_state;
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      let template = await this.props.firebase.getTemplate(
        this.props.match.params.id
      );
      // console.log(template);
      this.setState({
        mtmembers: true,
        templateName: template.title,
        mtQuestions: template.mtQuestions,
        title: template.title,

        questions: template.questions,
        id: template.id,
        type: template.type,
      });

      if (template.intro && template.intro.length > 0) {
        this.setState({
          intro: template.intro,
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(template.intro))
          ),
        });
      }

      if (template.outro && template.outro.length > 0) {
        this.setState({
          outro: template.outro,
          outroState: EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(template.outro))
          ),
        });
      }

      if (template.notDoneText && template.notDoneText.length > 0) {
        this.setState({
          notDoneText: template.notDoneText,
          notDoneState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(template.notDoneText)
            )
          ),
        });
      }
    }
  };

  handleChange = (editorState) => {
    console.log(this.state.selectedEditor);
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (this.state.selectedEditor === null) {
      this.setState({
        editorState,
        intro: html,
      });
    }

    if (this.state.selectedEditor === "outro") {
      this.setState({
        outroState: editorState,
        outro: html,
      });
    }

    if (this.state.selectedEditor === "notdone") {
      this.setState({
        notDoneState: editorState,
        notDoneText: html,
      });
    }
  };

  handleDropDown = (e) => {
    let field = e.target.id;
    let value = e.target.value;
    this.setState({ [field]: value });
  };

  handleInput = (e) => {
    let field = e.target.id;
    let value = e.target.value;
    this.setState({ [field]: value });
  };

  handleSwitch = (e) => {
    let field = e.target.name;
    let value = e.target.checked;
    console.log(field, value);
    this.setState({ [field]: value });
  };

  handleInvert = (e) => {
    let question = { ...this.state.mtQuestion };
    let field = e.target.id;
    let value = e.target.checked;
    question.invert = value;

    this.setState({ mtQuestion: question });
  };
  handleGroupMt = (e) => {
    let question = { ...this.state.mtQuestion };

    let field = e.target.id;
    let value = e.target.value;
    question.group = value;
    this.setState({ mtQuestion: question });
  };

  handleQuestions = (e, mt) => {
    let question = { ...this.state.question };
    let group = {};
    if (mt) question = { ...this.state.mtQuestion };

    let field = e.target.id;
    let value = e.target.value;

    question[field] = value;
    if (mt) {
      this.setState({ mtQuestion: question });
    } else {
      if (field === "group") {
        questionCats.main.filter((q) => {
          let values = value.split("-");
          if (q.id === Number(values[0])) {
            group.group = q;
            group.selected = q.sub[values[1]];
            console.log(group);
            question[field] = group;
          }
        });
      }

      this.setState({ question });
    }
  };

  handleAnswers = (mt) => {
    const left = mt ? this.state.mtQuestion.left : this.state.question.left;
    const right = mt ? this.state.mtQuestion.right : this.state.question.right;
    const invert = mt
      ? this.state.mtQuestion.invert
      : this.state.question.invert;
    const group = mt ? this.state.mtQuestion.group : this.state.question.group;

    if (left && right) {
      let question = { ...this.state.question };
      if (mt) question = { ...this.state.mtQuestion };

      question.options.push({ left, right, invert, group });
      question.left = "";
      question.right = "";

      if (mt) {
        this.setState({ mtQuestion: question });
      } else {
        this.setState({ question });
      }
    }
  };

  addQuestion = (mt) => {
    const question = mt ? this.state.mtQuestion : this.state.question;
    const questions = mt ? this.state.mtQuestions : this.state.questions;

    question.id = questions.length + 1;
    let array = questions;

    delete question.left;
    delete question.right;

    if (question.options.length > 0) {
      array.push(question);
      if (mt) {
        this.setState({
          mtQuestions: array,
          mtQuestion: {
            type: "null",
            question: "",
            description: "",
            left: "",
            right: "",
            options: [],
          },
        });
      } else {
        this.setState({
          questions: array,
          question: {
            type: "null",
            question: "",
            description: "",
            left: "",
            right: "",
            options: [],
          },
        });
      }
    }

    // if (left && right) {
    //   let question = { ...this.state.question };

    //   question.options.push({ left, right });
    //   question.left = "";
    //   question.right = "";
    //
    // }
  };

  submitSurvey = () => {
    let array = [];
    let data = {
      intro: this.state.intro,
      outro: this.state.outro,
      notDoneText: this.state.notDoneText,
      version: 1,
      type: this.state.type,
      title: this.state.templateName,
      questions: this.state.questions,
      mtQuestions: this.state.mtQuestions,
    };

    if (this.props.match.params.id) {
      data.id = this.state.id;
      this.props.firebase.createTemplate(data, data.id);
      this.props.history.push("/templates");
    } else {
      this.props.firebase.createTemplate(data);
      this.props.history.push("/templates");
    }
  };

  selectEditor(type) {
    console.log(type);
    this.setState({
      selectEditor: type,
    });
  }

  editQuestion = (e, id, type) => {
    let state = this.state.questions.map((q) => {
      if (q.id === id) {
        q[type] = e.target.value;
      }
      return q;
    });

    this.setState({ questions: state });
  };

  editMtQuestion = (e, id, type) => {
    console.log("edit mt questions");
    let state = this.state.mtQuestions.map((q) => {
      if (q.id === id) {
        q[type] = e.target.value;
      }
      return q;
    });
    console.log(state);

    this.setState({ mtQuestions: state });
  };

  editRating = (e, id, index, type) => {
    let state = this.state.questions.map((q) => {
      if (q.id === id) {
        let group = {};
        q.options.map((o, i) => {
          if (i === index) o[type] = e.target.value;
          if (type === "group") {
            questionCats.main.filter((a) => {
              let values = e.target.value.split("-");
              if (a.id === Number(values[0])) {
                group.group = a;
                group.selected = a.sub[values[1]];
                console.log(group);
                if (i === index) o[type] = group;
              }
            });
          }
        });
      }

      return q;
    });
    console.log(state);
    this.setState({ questions: state });
  };

  editMtRating = (e, id, index, type) => {
    let state = this.state.mtQuestions.map((q) => {
      if (q.id === id) {
        let group = {};
        q.options.map((o, i) => {
          if (i === index) o[type] = e.target.value;
          // if (type === "group") {
          //   questionCats.mt.filter((a) => {
          //     let values = e.target.value.split("-");
          //     if (a.id === Number(values[0])) {
          //       group.group = a;
          //       group.selected = a.sub[values[1]];
          //       console.log(group);
          //       if (i === index) o[type] = group;
          //     }
          //   });
          // }
        });
      }

      return q;
    });
    console.log(state);
    this.setState({ mtQuestions: state });
  };

  editGroup = (e, type, question, index) => {
    let value = e.target.value.split("-");

    let subId = Number(value[1]);
    let id = Number(value[0]);

    console.log(id, subId, index);

    // let question = this.state.questions.filter((q) => q.id == id);
    console.log(question, index);
    // console.log(question[0].options[index]);
    // console.log(question.options.filter((s) => s.id == subId));
    let state = this.state.questions.map((q) => {
      if (q.id === question.id) {
        let group = {};
        q.options.filter((a, i) => {
          questionCats.main.filter((b) => {
            if (b.id === id) {
              console.log(value, b.sub[subId]);
              group.group = b;
              group.selected = b.sub.filter((c) => c.id == subId)[0];
              console.log(group.group, group.selected);
              if (i === index) a[type] = group;
            }
          });
        });
        // q.options.map((o, i) => {
        //   if (i === index) o[type] = e.target.value;

        //   if (type === "group") {

        //   }
        // });
      }

      return q;
    });
    console.log(state);
    this.setState({ questions: state });
  };

  editMtGroup = (e, type, question, index) => {
    let value = e.target.value;

    let state = this.state.mtQuestions.map((q) => {
      if (q.id === question.id) {
        console.log(q);
        let group = {};
        q.options.filter((a, i) => {
          questionCats.mt.filter((b) => {
            if (b.slug === value) {
              group.group = null;
              group.selected = b;
              console.log(group.group, group.selected);
              if (i === index) a[type] = group;
            }
          });
        });
        // q.options.map((o, i) => {
        //   if (i === index) o[type] = e.target.value;

        // });
      }

      return q;
    });
    console.log(state);
    this.setState({ mtQuestions: state });
  };

  // editSwitch = (e) => {
  //   let field = e.target.name;
  //   let value = e.target.checked;
  //   console.log(field, value);
  //   this.setState({ [field]: value });
  // };

  editSwitch = (e, id, index, type) => {
    let state = this.state.mtQuestions.map((q) => {
      if (q.id === id) {
        q.options.map((o, i) => {
          if (i === index) o[type] = e.target.checked;
          console.log(o);
        });
      }

      return q;
    });
    console.log(state);
    this.setState({ mtQuestions: state });
  };

  handleFocus = (e) => {
    console.log(e);
  };

  handleFocusOut = (e) => {
    console.log(e);
  };

  render() {
    const {
      editorState,
      outroState,
      notDoneState,
      mtmembers,
      templateName,
      question,
      questions,
      mtQuestion,
      mtQuestions,
      selectedEditor,
    } = this.state;

    let editorS = editorState;
    if (selectedEditor === "outro") editorS = outroState;
    if (selectedEditor === "notdone") editorS = notDoneState;
    return (
      <div className={`forms`}>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div
                className="back-button"
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back
              </div>
              <h1 className="page-header">
                {this.props.match.params.id
                  ? "Edit Survey Template"
                  : "Add New Survey Template"}
              </h1>
            </div>
          </div>
        </div>
        <div className="container content form">
          <div className="row">
            <div className="col col-4">
              <div className="form-field">
                <h2 className="input-title">Template Name</h2>
                <div className="input-block">
                  <Input
                    onChange={(e) => this.handleInput(e)}
                    value={templateName}
                    id="templateName"
                    name="templateName"
                    placeholder="Example: EIT food"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div className="col col-4">
              <div className="form-field">
                <h2 className="input-title">Choose Type</h2>
                <div className="input-block">
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    value={
                      this.state.type.length > 0 ? this.state.type : "null"
                    }
                    onChange={(e) => this.handleDropDown(e)}
                  >
                    <option disabled value="null">
                      Choose a Type
                    </option>
                    <option value="lead2scale">Lead2Scale</option>
                  </Input>
                </div>
              </div>
            </div>

            <div className="col col-4">
              <div className="form-field">
                <h2 className="input-title">Choose Extra Modules</h2>
                <div className="input-block">
                  <CustomInput
                    onChange={(e) => this.handleSwitch(e)}
                    type="switch"
                    id="mtmembers"
                    name="mtmembers"
                    label="Management Team Members"
                    checked={this.state.mtmembers ? "checked" : false}
                  />
                </div>
              </div>
            </div>

            <div className="col col-12">
              <div className="form-field">
                <div className="big-text-labels">
                  <h2
                    className="input-title"
                    onClick={() => this.setState({ selectedEditor: null })}
                  >
                    Introduction Text
                    {this.state.intro.length > 0 ? (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    ) : (
                      <FontAwesomeIcon icon={faTimesCircle} />
                    )}
                  </h2>
                  <h2
                    className="input-title"
                    onClick={() => this.setState({ selectedEditor: "outro" })}
                  >
                    Ending Text{" "}
                    {
                      (this.state.outro,
                      this.state.outro.length > 0 ? (
                        <FontAwesomeIcon icon={faCheckCircle} />
                      ) : (
                        <FontAwesomeIcon icon={faTimesCircle} />
                      ))
                    }
                  </h2>
                  <h2
                    className="input-title"
                    onClick={() => this.setState({ selectedEditor: "notdone" })}
                  >
                    Ending not done Text{" "}
                    {this.state.notDoneText.length > 0 ? (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    ) : (
                      <FontAwesomeIcon icon={faTimesCircle} />
                    )}
                  </h2>
                </div>

                <div className={`input-block`}>
                  <Editor
                    id="intro"
                    editorState={editorS}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.handleChange}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      blockType: {
                        inDropdown: true,
                        options: [
                          "Normal",
                          "H1",
                          "H2",
                          "H3",
                          "H4",
                          "H5",
                          "H6",
                          "Blockquote",
                          "Code",
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      colorPicker: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        colors: ["rgb(97,189,109, 0)", "rgb(0,0,0)"],
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <QuestionsForm
              question={question}
              handleQuestions={this.handleQuestions}
              addQuestion={this.addQuestion}
              handleAnswers={this.handleAnswers}
            />
            <>
              {questions.length > 0 ? (
                <div className="col col-12">
                  <div className="preview">
                    {questions.map((q, i) => {
                      return (
                        <div key={q.id}>
                          <div className="col col-12">
                            <EditQuestionsForm
                              id={q.id}
                              question={q}
                              editQuestion={this.editQuestion}
                              editRating={this.editRating}
                              editGroup={this.editGroup}
                            />
                          </div>
                          <div className="col col-12 mb-5">
                            <div className="title">
                              {q.id}: {q.question}
                            </div>
                            <div className="description">
                              <small>{q.description}</small>
                            </div>
                            <div className="type">
                              <RatingExample
                                data={q}
                                handleFocus={this.handleFocus}
                                handleFocusOut={this.handleFocusOut}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </>

            {mtmembers ? (
              <>
                {" "}
                <MtForm
                  question={mtQuestion}
                  handleQuestions={this.handleQuestions}
                  addQuestion={this.addQuestion}
                  handleAnswers={this.handleAnswers}
                  handleSwitch={this.handleInvert}
                  handleGroup={this.handleGroupMt}
                />
                <>
                  {mtQuestions.length > 0 ? (
                    <div className="col col-12">
                      <div className="preview">
                        {mtQuestions.map((mt, i) => {
                          return (
                            <div key={mt.id} className="col col-12 mb-5">
                              <div className="title">
                                {mt.id}: {mt.question}
                              </div>
                              <div className="col col-12">
                                <EditMtForm
                                  id={mt.id}
                                  question={mt}
                                  editQuestion={this.editMtQuestion}
                                  editRating={this.editMtRating}
                                  editGroup={this.editMtGroup}
                                  handleSwitch={this.editSwitch}
                                />
                              </div>
                              <div className="description">
                                <small>{mt.description}</small>
                              </div>
                              <div className="type">
                                <RatingExample
                                  data={mt}
                                  handleFocus={this.handleFocus}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </>
              </>
            ) : null}
            <div className="col col-12">
              <span
                className={`input-submit ${
                  ""
                  //question.options.length === 0 ? "disabled" : ""
                }`}
                onClick={() => this.submitSurvey()}
              >
                Submit Survey
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyses, loading, navigator }) => {
  return {
    analyses,
    loading,
    navigator,
  };
};

export default connect(mapStateToProps, {
  searchNavigator,
  clearSearch,
})(withFirebase(withRouter(withCookies(AddSurvey))));
