import { GET_COMPANIES } from "../actions/types";

const initialState = {
  companies: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_COMPANIES:
      return { companies: payload };

    default:
      return state;
  }
};
