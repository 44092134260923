import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { Progress } from "reactstrap";
// import Redux Actions
import { getSingleAnalysis } from "../../actions/analyses";
import { loadingToggle } from "../../actions/loading";
import { RatingMultiple, RatingSingle, Rating } from "../../lib/questionTypes";

// Import components

// Import Constants

class Survey extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      access: false,
      step: 1,
      mtStep: 0,
      questions: [],
      mtQuestions: [],
      intro: "",
      title: "",
      step1: "",
      totalQuestions: 0,
      progress: 0,
      initial: false,
      initialSurvey: true,
      finished: false,
    };
    // console.log(this.props);
  }

  onFirebaseValueChanged = async (snapshot) => {
    // console.log(snapshot.data());
    this.getSurvey(
      this.props.match.params.id,
      Number(this.props.match.params.code),
      snapshot.data()
    );
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      let enviroment =
        process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
      // this.props.firebase
      //   .database()
      //   .collection(enviroment)
      //   .doc(this.props.match.params.id)
      //   .onSnapshot((querySnapshot) => {
      //     this.onFirebaseValueChanged(querySnapshot);
      //   });

      this.getSurvey(
        this.props.match.params.id,
        Number(this.props.match.params.code)
      );
    }
  }
  updatePercentage = (questions, mt, initial) => {
    let percentage = this.props.firebase.getPercentage(questions, mt);
    let oldPercentage = this.state.progress;
    this.setState({
      progress: percentage,
    });

    if (oldPercentage < percentage)
      this.props.firebase.saveProgress(percentage, this.props.match.params.id);

    if (!initial) {
      return { questions, mt };
    }
  };

  getSurvey = async (id, code) => {
    this.props.loadingToggle("single", true);
    let res = await this.props.firebase.getSurvey(id);
    // console.log(id, code, res);
    const surveyCode = res.code;

    if (surveyCode === code && !this.state.initial) {
      let newQuestions = this.updatePercentage(
        res.survey.questions,
        res.survey.mt,
        this.state.initial
      );
      console.log(res, "new MT questions?");
      let data = {
        access: true,
        step: 1,
        questions: res.survey.questions,
        mtQuestions: res.survey.mt,
        intro: res.survey.intro,
        notDoneText: res.survey.notDoneText,
        outro: res.survey.outro,
        title: res.survey.title,
        step1: res.survey.step1,
        initial: true,
        completionDate: res.completionDate ? res.completionDate : null,
        survey: res,
      };
      this.setState(data);
      this.props.loadingToggle("single", false);
    } else {
      this.setState({
        questions: res.survey.questions,
        mtQuestions: res.survey.mt,
      });
      this.updatePercentage(res.survey.questions, res.survey.mt);
      this.props.loadingToggle("single", false);
    }
  };

  componentWillUnmount() {
    // console.log("Single survey unmount");
    // this.unsubscribe();
  }

  createMarkup(html) {
    return {
      __html: html,
    };
  }

  nextStep = () => {
    this.setState({
      step: this.state.step + 1,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  backStep = () => {
    this.setState({
      step: this.state.step - 1,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  nextMt = () => {
    this.setState({
      mtStep: this.state.mtStep + 1,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  backMt = () => {
    this.setState({
      mtStep: this.state.mtStep - 1,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  getInput = (data, mt) => {
    if (data.type === "rating") {
      return (
        <Rating data={data} answerQuestion={this.answerQuestion} mt={mt} />
      );
    }
  };

  answerQuestion = (event, data, index, mt) => {
    let state = this.state.questions;
    if (mt) state = this.state.mtQuestions;
    console.log(state);
    state.filter((s) => {
      if (s.id === data.id) {
        console.log(s);
      }
    });
    let answer = event.target.value;
    data.options[index].answer = answer;

    console.log(data.options[index].answer);

    let percentage = this.getPercentage(
      this.state.questions,
      this.state.mtQuestions
    );

    console.log(percentage);

    if (mt) {
      this.setState({
        mtQuestions: state,
      });
    } else {
      this.setState({
        questions: state,
      });
    }
    this.setState({
      progress: percentage,
    });
    this.props.firebase.answerQuestion(
      this.props.match.params.id,
      data,
      answer,
      index
    );

    // let percentage = this.props.firebase.getPercentage(questions, mt);
    this.props.firebase.saveProgress(percentage, this.props.match.params.id);

    // this.setState({
    //   selectedOption: event.target.value,
    // });
  };

  skipMt = async (mt, index) => {
    console.log(mt, index);
    let state = this.state.mtQuestions;
    state = state.filter((mt, i) => {
      if (i === index) {
        mt.skip = true;
      }
      return mt;
    });
    console.log(state);
    let newMt = await this.props.firebase.skipMt(
      index,
      this.props.match.params.id
    );
    this.setState({
      mtQuestions: state,
    });
    let page = index + 1;
    let percentage = this.getPercentage(
      this.state.questions,
      this.state.mtQuestions
    );
    console.log(percentage);
    this.setState({
      progress: percentage,
    });
    if (page < this.state.mtQuestions.length) {
      // this.nextMt();
      // if(newMt) this.setState({mtQuestions:newMt})
      // this.submitSurvey(true);
    } else {
      this.submitSurvey(true);
    }
  };

  getPercentage = (questions, mt, initial) => {
    let allQuestions = [];
    let i = 0;
    let answered = 0;

    questions.map((m) => {
      m.options.map((o) => {
        i++;
        o.id = i;
        let data = {
          index: i,
          done: o.answer ? true : false,
          type: "general",
        };
        if (data.done) answered++;
        allQuestions.push(data);

        return m;
      });
    });
    mt.map((m, a) => {
      if (!m.skip) {
        m.questions.map((q) => {
          q.mtmember = m.name;
          // allQuestions.push(q);
          q.options.map((o) => {
            i++;
            o.id = i;
            o.mtId = a;
            let data = {
              index: i,
              done: o.answer ? true : false,
              type: "mt",
            };
            if (data.done) answered++;

            allQuestions.push(data);
            return q;
          });
        });
      }
    });
    // console.log(answered, allQuestions.length);
    let percentage = (answered / allQuestions.length) * 100;
    percentage = Number(percentage.toFixed(0));
    // console.log(percentage);

    return percentage;
  };

  submitSurvey = (skipped) => {
    console.log("survey done");
    this.setState({
      finished: true,
      initialSurvey: false,
    });
    console.log(this.state.progress);
    if (this.state.progress === 100) {
      this.props.firebase.finishSurvey(this.props.match.params.id);
      let email = this.state.survey.email;
      let company = this.state.survey.companyName;
      let name = this.state.survey.name;
      let link = `${process.env.REACT_APP_SERVER_URL}survey/${this.props.match.params.id}/${this.props.match.params.code}`;
      // api.post(
      //   `/welcome/?email=${email}&link=${link}&name=Rodney Zeldenrijk&company=Mimetas`
      // );
    } else {
      this.props.firebase.saveSurvey(this.props.match.params.id);
    }
    // this.props.firebase.saveProgress(
    //   this.state.progress,
    //   this.props.match.params.id
    // );
  };

  startSurvey = async () => {
    this.setState({
      finished: false,
      step: 2,
      mtStep: 0,
      initial: false,
    });
    let enviroment =
      process.env.REACT_APP_ENV === "production" ? "surveys" : "surveys_test";
    let survey = await this.props.firebase
      .database()
      .collection(enviroment)
      .doc(this.props.match.params.id)
      .get();

    this.getSurvey(
      this.props.match.params.id,
      Number(this.props.match.params.code),
      survey.data()
    );
  };

  render() {
    const {
      step,
      finished,
      questions,
      step1,
      mtQuestions,
      access,
      progress,
      mtStep,
      completionDate,
      notDoneText,
      outro,
      intro,
      initialSurvey,
    } = this.state;

    if (access) {
      return (
        <div className={`single ${initialSurvey ? "" : "show-empty"}`}>
          <div className="container">
            <div className="row">
              <div className="col col-12 mt-4 progress-col">
                <Progress value={progress}>
                  <span>{progress + "%"}</span>
                </Progress>
              </div>
              <div className="col col-12 mt-4">
                <div className="title-block">
                  <h1>{this.state.title}</h1>
                </div>
              </div>
              {!completionDate ? (
                <>
                  {!finished ? (
                    <>
                      {step === 1 ? (
                        <>
                          <div className="col col-12">
                            <div className="content-block">
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  intro
                                )}
                              />
                            </div>
                          </div>
                          <div className="col col-12">
                            <div
                              className="action-block"
                              onClick={() => this.nextStep()}
                            >
                              <div className="button start">Start survey</div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {step === 2 ? (
                        <>
                          <div className="col col-12">
                            <div className="survey-block">
                              <h2>{step1}</h2>
                              <div className="row">
                                {questions
                                  ? questions.map((q) => {
                                      return (
                                        <div
                                          key={q.id}
                                          className={`col col-12 mb-5 `}
                                        >
                                          <div className="title">
                                            {q.id}: {q.question}
                                          </div>
                                          <div className="description">
                                            <small>{q.description}</small>
                                          </div>
                                          <div className="type">
                                            {this.getInput(q, false)}
                                          </div>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div className="col col-12">
                            <div className="action-block">
                              <div
                                className="button start back"
                                onClick={() => this.backStep()}
                              >
                                Back
                              </div>
                              <div
                                className="button start next"
                                onClick={() => this.nextStep()}
                              >
                                Next
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      {step === 3 ? (
                        <>
                          {mtQuestions.map((q, index) => {
                            q.questions.map((mt) => {
                              mt.mt = true;
                              mt.mtMember = q.name;
                              return mt;
                            });
                            let page = index + 1;
                            if (!q.skip) {
                              return (
                                <div
                                  id={`mt-${index}`}
                                  className={`mtteams ${
                                    this.state.mtStep === index
                                      ? "show"
                                      : "hide"
                                  } col col-12`}
                                  key={index}
                                >
                                  <div className="survey-block">
                                    <h2>MT Member: {q.name}</h2>
                                    {!q.skip ? (
                                      <div className="skip-container">
                                        <div
                                          className="button skip-button"
                                          onClick={() => this.skipMt(q, index)}
                                        >
                                          Not applicable *
                                        </div>
                                        <small>
                                          * I do not work with this leadership
                                          team member on a daily basis
                                        </small>
                                      </div>
                                    ) : null}

                                    <div className="row">
                                      {q.questions
                                        ? q.questions.map((a, i) => {
                                            return (
                                              <div
                                                key={i + 20}
                                                className="col col-12 mb-5"
                                              >
                                                <div className="title">
                                                  {a.id}: {a.question}
                                                </div>
                                                <div className="description">
                                                  <small>{a.description}</small>
                                                </div>
                                                <div className="type">
                                                  {this.getInput(a, true)}
                                                </div>
                                              </div>
                                            );
                                          })
                                        : null}
                                    </div>
                                  </div>

                                  <div className="action-block">
                                    {index === 0 ? (
                                      <div
                                        className="button start back"
                                        onClick={() => this.backStep()}
                                      >
                                        Back
                                      </div>
                                    ) : (
                                      <div
                                        className="button start back"
                                        onClick={() => this.backMt()}
                                      >
                                        Back
                                      </div>
                                    )}

                                    {page < mtQuestions.length ? (
                                      <div
                                        className="button start next"
                                        onClick={() => this.nextMt()}
                                      >
                                        Next
                                      </div>
                                    ) : (
                                      <div
                                        className="button start next"
                                        onClick={() => this.submitSurvey()}
                                      >
                                        Finish
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  id={`mt-${index}`}
                                  className={`mtteams ${
                                    this.state.mtStep === index
                                      ? "show"
                                      : "hide"
                                  } col col-12`}
                                  key={index}
                                >
                                  <div className="survey-block">
                                    <h2>MT Member: {q.name}</h2>
                                    {/* {mtQuestions.length > 1 ? (
                                        <div className="skip-container">
                                          <div className="button skip-button" onClick={() => this.skipMt(q, index)}>
                                            Not applicable *
                                          </div>
                                          <small>
                                            * I do not work with this leadership team member on a daily basis
                                          </small>
                                        </div>
                                      ) : null} */}

                                    <div className="row">
                                      <div className="col col-12">
                                        You do not work with this leadership
                                        team member on a daily basis
                                      </div>
                                    </div>
                                  </div>

                                  <div className="action-block">
                                    {index === 0 ? (
                                      <div
                                        className="button start back"
                                        onClick={() => this.backStep()}
                                      >
                                        Back
                                      </div>
                                    ) : (
                                      <div
                                        className="button start back"
                                        onClick={() => this.backMt()}
                                      >
                                        Back
                                      </div>
                                    )}

                                    {page < mtQuestions.length ? (
                                      <div
                                        className="button start next"
                                        onClick={() => this.nextMt()}
                                      >
                                        Next
                                      </div>
                                    ) : (
                                      <div
                                        className="button start next"
                                        onClick={() => this.submitSurvey()}
                                      >
                                        Finish
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {progress < 100 ? (
                        <>
                          <div className="col col-12">
                            <div className="content-block">
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  notDoneText
                                )}
                              />
                            </div>
                          </div>
                          <div className="col col-12">
                            <div
                              className="action-block"
                              onClick={() => this.startSurvey()}
                            >
                              <div className="button start">Continue</div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col col-12">
                          <div className="content-block">
                            <div
                              dangerouslySetInnerHTML={this.createMarkup(outro)}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="col col-12">
                  <div className="content-block">
                    <div dangerouslySetInnerHTML={this.createMarkup(outro)} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="single">
          <div className="container">
            <div className="row">
              <div className="col col-12 mt-4">
                <div className="content-block">
                  {this.props.loading.single
                    ? "loading"
                    : "You don't have access to this page"}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = ({ analyses, loading }) => {
  return {
    analyses,
    loading,
  };
};

export default connect(mapStateToProps, { getSingleAnalysis, loadingToggle })(
  withFirebase(withRouter(Survey))
);
