import {
  GET_ANALYSES,
  SEARCH_ANALYSES,
  GET_SINGLE_ANALYSES,
  CLEAR_SEARCH, SEARCH_NAVIGATOR,
} from "./types";
import { loadingToggle } from "./loading";
import axios from "axios";

export const getAnalyses = (payload) => (dispatch) => {
  dispatch({
    type: GET_ANALYSES,
    payload: payload,
  });
  dispatch(loadingToggle("analyses", false));
};

export const getSingleAnalysis = (payload) => (dispatch) => {
  dispatch({
    type: GET_SINGLE_ANALYSES,
    payload: payload,
  });
  dispatch(loadingToggle("single", false));
};

export const clearSearch = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH,
  });
  dispatch(loadingToggle("single", false));
};

const filterPeopleByQuery = (query, state) => {
  // console.log(query, state);
  query = query.toLowerCase();
  let newState = [];
  state.map((person) => {
    if (
      person.firstName.toLowerCase().includes(query) ||
      person.lastName.toLowerCase().includes(query) ||
      person.company.toLowerCase().includes(query) ||
      (person.industry !== null &&
        person.industry.toLowerCase().includes(query))
    ) {
      newState.push(person);
    }
    return false;
  });
  return newState;
};

export const searchNavigator = (payload, cookies, companies) => async (
  dispatch
) => {
  if (payload.length > 1) {
    let filtered = await filterPeopleByQuery(payload, companies);

    // console.log(filtered);

    dispatch({
      type: SEARCH_NAVIGATOR,
      payload: { found: true, list: filtered },
    });
    // dispatch({
    //   type: SEARCH_ANALYSES,
    //   payload: { found: true, list: companies },
    // });

    // let companies = resp.data.people.filter(
    //   (a) =>
    //     a.isAdmin === false &&
    //     a.company.toLowerCase().includes(payload.toLowerCase())
    // );
    // if (companies.length > 0) {
    //   console.log(companies);
    //   dispatch({
    //     type: SEARCH_ANALYSES,
    //     payload: { found: true, list: companies },
    //   });
    // } else {
    //   dispatch({
    //     type: SEARCH_ANALYSES,
    //     payload: { found: false, list: [] },
    //   });
    // }
  } else {
    console.log([]);
    dispatch({
      type: SEARCH_NAVIGATOR,
      payload: { found: false, list: [] },
    });
  }
  // if (payload.length < 1) {
  //   dispatch({
  //     type: SEARCH_ANALYSES,
  //     payload: { found: false, list: [] },
  //   });
  // }
};

export const searchAnalyses = (payload, analyses) => async (dispatch) => {
  dispatch(loadingToggle("search", true));
  let array = [];
  if (payload.length > 2) {
    console.log(payload)
    console.log(analyses)

    analyses.map(doc => {
      console.log(doc.navigator.company.toLowerCase().includes(payload.toLowerCase()))
      if (doc.navigator.company.toLowerCase().includes(payload.toLowerCase()))
        array.push(doc);
    })


    // await firebase
    //   .database()
    //   .collection("companies")
    //   .get()
    //   .then((docs) => {
    //     docs.forEach((doc) => {
    //       // doc.data() is never undefined for query doc snapshots
    //       let analyses = doc.data()
    //       analyses.map(a => {

    //       })
    //       if (doc.data().name.toLowerCase().includes(payload.toLowerCase()))
    //         array.push(doc.data());
    //     });
    //   })
    //   .catch(function (error) {
    //     console.log("Error getting documents: ");
    //     console.log(error)
    //   });
  }

  console.log(array);
  if (array.length > 0) {
    dispatch({
      type: SEARCH_ANALYSES,
      payload: { found: true, list: array },
    });
  } else {
    dispatch({
      type: SEARCH_ANALYSES,
      payload: { found: false, list: [] },
    });
  }

  dispatch(loadingToggle("search", false));

  // if (payload.length === 0)
  //   dispatch({
  //     type: SEARCH_ANALYSES,
  //     payload: { found: false, list: [] },
  //   });
};
