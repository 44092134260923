import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__({
      trace: true, // (action) => { return ‘trace as string’; }
      traceLimit: 25,
    })
  : (f) => f;

const enhancer = compose(applyMiddleware(thunk), devTools);

const store = createStore(rootReducer, enhancer);

export default store;
