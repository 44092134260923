import React from "react";
// Import libraries
import { withRouter } from "react-router-dom";

// Import libraries
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

const SearchBar = (props) => {
  // console.log(props);
  return (
    <>
      <InputGroup>
        <Input
          // onInput={(e) => props.search(e)}
          onChange={(e) => props.search(e)}
          placeholder="Search or add a company"
          value={props.query}
        />
      </InputGroup>
      <ListGroup className="suggestions" flush>
        {props.suggestions.length > 0 ? (
          <>
            {props.suggestions.map((l, i) => {
              // console.log(l)
              return (
                <ListGroupItem
                  className="search-suggestion"
                  key={i}
                  onClick={() => props.addNew(l)}
                >
                  {l}
                </ListGroupItem>
              );
            })}
          </>
        ) : null}
        <ListGroupItem
          className="search-suggestion"
          onClick={() => props.addNew(props.query)}
        >
          Add new company
        </ListGroupItem>
      </ListGroup>
    </>
  );
};
export default withRouter(SearchBar);
