import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/main.scss";
import Firebase, { FirebaseContext } from "./firebase/index";
import Auth0ProviderWithHistory from "./auth0";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <CookiesProvider>
    <FirebaseContext.Provider value={new Firebase()}>
      <Provider store={configureStore}>
        <App />
      </Provider>
    </FirebaseContext.Provider>
  </CookiesProvider>,
  document.getElementById("root")
);
