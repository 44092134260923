import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";

// import Redux Actions
import { getSingleAnalysis } from "../../actions/analyses";
import { loadingToggle } from "../../actions/loading";

// Import components
import Widget from "./components/analyses";
// Import Constants

class Single extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.getAnalysis(this.props.match.params.id);
  }

  getAnalysis = (id) => {
    this.props.loadingToggle("single", true);

    this.analyses = this.props.firebase
      .singleAnalysis(id)
      .get()
      .then((snapshot) => {
        this.props.getSingleAnalysis(snapshot.data());
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  componentWillUnmount() {
    console.log("Single analysis unmount");
    // this.unsubscribe();
  }

  render() {
    const analysis = this.props.analyses.single;
    console.log(analysis);
    console.log(analysis.updatedAt);
    if (!_.isEmpty(analysis)) {
      return (
        <div className="single">
          <div className="container">
            <div className="row">
              <div className="col col-12">
                <div className="title">
                  <h1>{analysis.navigator.company}</h1>
                  <span>
                    Last run {moment(Date(analysis.updatedAt)).format("LL")}
                  </span>
                </div>
              </div>
              <div className="col col-4">
                <Widget title="Navigator data" data={analysis.navigator} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="single">
          <div className="container">
            <div className="row">
              <div className="col col-12">No analysis found </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = ({ analyses }) => {
  return {
    analyses,
  };
};

export default connect(mapStateToProps, { getSingleAnalysis, loadingToggle })(
  withFirebase(withRouter(Single))
);
