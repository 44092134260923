import { combineReducers } from "redux";
import login from "./auth";
import analyses from "./analyses";
import loading from "./loading";
import navigator from "./navigator";
import surveys from "./surveys";

export default combineReducers({
  auth: login,
  analyses,
  loading,
  navigator,
  surveys,
});
