import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const analyses = (props) => {
  return (
    <div className="widget mt-5">
      <div className="widget-title">
        {props.title} <span className="text-link">View details</span>
      </div>

      <div className="widget-box">
        {!props.loading ? (
          <ul className="list">
            {/* {props.list.map((l) => {
              console.log(props);
              return (
                <li
                  className="item"
                  key={l.uid}
                  onClick={() => props.history.push(`/analyses/${l.uid}`)}
                >
                  {l.navigator.company}
                  {props.type === "analyses" ? (
                    <>
                      {l.ready ? (
                        <FontAwesomeIcon icon={faCheckCircle} />
                      ) : (
                        <FontAwesomeIcon icon={faTimesCircle} />
                      )}
                    </>
                  ) : null}{" "}
                  <span>{moment(l.createdAt).format("LL")}</span>
                </li>
              );
            })} */}
          </ul>
        ) : (
          <span>loading</span>
        )}
      </div>
    </div>
  );
};
export default withRouter(analyses);
