const config = {
  apiKey: "AIzaSyAVcmPs-cRZ34u15ErQpdssoqYUz8Mb5IA",
  authDomain: "digital-proxies.firebaseapp.com",
  databaseURL: "https://digital-proxies.firebaseio.com",
  projectId: "digital-proxies",
  storageBucket: "digital-proxies.appspot.com",
  messagingSenderId: "144622109023",
  appId: process.env.REACT_APP_APPID,
  // appId: "1:144622109023:web:05bbd1dec187edc07536ea", //production
};
export default config;
