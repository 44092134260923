import React from "react";
// Import libraries
import { withRouter } from "react-router-dom";

// Import libraries
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

const SearchBar = (props) => {
  return (
    <>
      <InputGroup>
        <Input
          // onInput={(e) => props.search(e)}
          onChange={(e) => props.search(e)}
          placeholder="Search company analysis"
          value={props.query}
        />
      </InputGroup>
      {props.suggestions.found ? (
        <ListGroup className="suggestions" flush>
          {props.suggestions.list.map((l, i) => {
            console.log(l)
            return (
              <ListGroupItem
                className="search-suggestion"
                key={i}
              // onClick={() => props.getNavigatorData(l)}
              >
                {l.company}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      ) : null}
    </>
  );
};
export default withRouter(SearchBar);
