import React from "react";

// Import libraries
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faTrashAlt,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

// Import helpers
import { withFirebase } from "../../firebase";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
// import Redux Actions
import { searchNavigator, clearSearch } from "../../actions/analyses";

// Import components

// Import Constants
import { NavigatorReportQuestions } from "../../lib/constants";

// Import Icons
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Form from "reactstrap/lib/Form";
import "../../scss/dashboard.scss";

import "../css/normalize.css";
import "../css/menu_cornermorph.css";

class Templates extends React.Component {
  _initial_state = {
    surveys: [],
    menu: false,
  };

  constructor(props) {
    super(props);

    this.state = this._initial_state;
    this.templates = this.props.firebase.database().collection("templates");
  }

  onFirebaseValueChanged = async (snapshot) => {
    this.getTemplates();
  };

  componentDidMount() {
    this.templates.onSnapshot((querySnapshot) =>
      this.onFirebaseValueChanged(querySnapshot)
    );
  }
  toggleMenu() {
    this.setState({
      menu: this.state.menu ? false : true,
    });
  }
  getTemplates = async () => {
    let templates = await this.props.firebase.getTemplates();
    this.setState({
      surveys: templates,
    });
  };
  goTo = (type, item) => {
    if (type === 1) {
      this.props.history.push("/templates/new");
    }

    if (type === 2) {
      // console.log(item.id);
      this.props.history.push("/templates/edit/" + item.id);
    }
  };
  render() {
    const { surveys } = this.state;

    return (
      <div className={`new-analysis ${this.state.menu ? "show-menu" : null}`}>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <h1>Survey Templates</h1>
              <div className="create">
                <div
                  className="create-button"
                  id="open-button"
                  onClick={(e) => this.toggleMenu()}
                  // onClick={() => this.props.history.push("/create/analysis")}
                >
                  <FontAwesomeIcon icon={faPlusSquare} />
                </div>
                <div className="menu-wrap">
                  <nav className="menu">
                    <div className="link-list">
                      <span onClick={() => this.goTo(1)}>
                        Create new survey template
                      </span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container surveys">
          <div className="row">
            {surveys.map((s, i) => {
              return (
                <div
                  key={i}
                  className="col col-md-4 col-lg-4 col-sm-6 col-xs-6"
                >
                  <div className="widget survey-item">
                    <div className="list-actions">
                      <div
                        className="action duplicate"
                        onClick={(e) =>
                          this.props.firebase.duplicateTemplate(e, s.id)
                        }
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </div>
                      <div
                        className="action delete"
                        onClick={(e) =>
                          this.props.firebase.removeTemplate(e, s.id)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>
                    </div>
                    <div className="widget-title templates">
                      <div>
                        {s.title}
                        <br />
                        <span
                          onClick={() => this.goTo(2, s)}
                          className="text-link"
                        >
                          Edit Template
                        </span>
                      </div>
                    </div>
                    <div className="details">
                      <p>Type: {s.type}</p>
                      <p>Report version: {s.version}</p>
                      <p># Questions: {s.questions.length}</p>
                      <p># MT Questions: {s.mtQuestions.length}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ analyses, loading, navigator }) => {
  return {
    analyses,
    loading,
    navigator,
  };
};

export default connect(mapStateToProps, {
  searchNavigator,
  clearSearch,
})(withFirebase(withRouter(withCookies(Templates))));
