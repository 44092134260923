// Auth
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SAVE_AUTH = "SAVE_AUTH";
export const LOGIN_ERROR = "LOGIN_ERROR";

// Navigator companies
export const GET_COMPANIES = "GET_COMPANIES";
export const SEARCH_NAVIGATOR = "SEARCH_NAVIGATOR";

// analyses
export const GET_ANALYSES = "GET_ANALYSES";
export const SEARCH_ANALYSES = "SEARCH_ANALYSES";
export const GET_SINGLE_ANALYSES = "GET_SINGLE_ANALYSES";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

// Loading
export const LOADING = "LOADING";

// surveys
export const GET_SURVEYS = "GET_SURVEYS";
export const SHOW_NOTICE = "SHOW_NOTICE";
export const HIDE_NOTICE = "HIDE_NOTICE";
