import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import _ from "lodash";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import "../scss/Navigation.scss";
import { logoutAction } from "../actions/auth";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <div>
        {!_.isEmpty(this.props.auth.user) ? (
          <Navbar dark expand="md">
            <NavbarBrand href="/">The Survey Tool</NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    activeClassName="active"
                    exact
                    tag={RRNavLink}
                    to="/"
                  >
                    Dashboard
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    activeClassName="active"
                    tag={RRNavLink}
                    to="/templates"
                  >
                    Survey Templates
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    href="/"
                    onClick={() => this.props.logoutAction(this.props.cookies)}
                  >
                    Logout
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        ) : null}
      </div>
    );
  }
}

export default connect(null, { logoutAction })(withCookies(Navigation));
